import { ErrorBoundary, FallbackRender } from '@sentry/react';
import { FC, ReactElement, ReactNode } from 'react';

/**
 * A reusable ErrorBoundary component for Sentry.
 * @param children - React children to render within the boundary.
 * @param fallback - Custom fallback UI in case of an error.
 */

interface ErrorBoundaryProviderProps {
  children: ReactNode;
  fallback: ReactElement | FallbackRender;
}

export const ErrorBoundaryProvider: FC<ErrorBoundaryProviderProps> = ({ children, fallback }) => (
  <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>
);
