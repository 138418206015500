import { IconSizingTokenKey } from '@aily/ui-theme';
import { buttonClasses } from '@mui/base';
import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { IconName, icons, IconWrapper } from '../../icons';
import { ButtonBase, ButtonBaseProps } from '../ButtonBase';

export interface ButtonProps extends ButtonBaseProps {
  variant?: ButtonVariant;
  startIcon?: IconName;
  endIcon?: IconName;
  iconSize?: IconSizingTokenKey;
  sx?: SxProps<Theme>;
}

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

export const ButtonRoot = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<ButtonProps>(({ theme, variant = 'primary' }) => ({
  gap: theme.tokens.spacing['xsmall'],
  borderRadius: theme.tokens.borderRadius['large'],
  minWidth: 136,
  maxWidth: '100%',
  height: 40,
  padding: theme.spacing(theme.tokens.spacing['xxsmall'], theme.tokens.spacing['medium']),

  ...theme.tokens.typography['heading.button'],
  lineHeight: 1,

  // Variant styles
  ...(variant === 'primary' && {
    border: `1px solid ${theme.tokens.color['text.regular']}`,
    backgroundColor: theme.tokens.color['text.regular'],
    color: theme.palette.getContrastText(theme.tokens.color['text.regular']),
    [`&.${buttonClasses.disabled}`]: {
      borderColor: theme.tokens.color['text.disabled'],
      backgroundColor: theme.tokens.color['text.disabled'],
    },
  }),

  ...(variant === 'secondary' && {
    border: `1px solid ${theme.tokens.color['text.regular']}`,
    backgroundColor: 'transparent',
    color: theme.tokens.color['text.regular'],
    [`&.${buttonClasses.disabled}`]: {
      borderColor: theme.tokens.color['text.disabled'],
      color: theme.tokens.color['text.disabled'],
    },
  }),

  ...(variant === 'tertiary' && {
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.tokens.color['text.regular'],
    [`&.${buttonClasses.disabled}`]: {
      color: theme.tokens.color['text.disabled'],
    },
  }),
}));

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  startIcon,
  endIcon,
  iconSize = 'icon.xxsmall',
  children,
  ...rest
}) => {
  const StartIconComponent = startIcon ? icons[startIcon] : null;
  const EndIconComponent = endIcon ? icons[endIcon] : null;

  return (
    <ButtonRoot variant={variant} {...rest}>
      {StartIconComponent && (
        <IconWrapper>
          <StartIconComponent size={iconSize} />
        </IconWrapper>
      )}
      {children}
      {EndIconComponent && (
        <IconWrapper>
          <EndIconComponent size={iconSize} />
        </IconWrapper>
      )}
    </ButtonRoot>
  );
};

export default Button;
