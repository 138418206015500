import { alpha, Box, Button, Stack, styled, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { ClosableModal, useSlideshow } from '../../../../components';
import { useAilyAgent } from '../../providers';

const StyledTextContainer = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  display: 'flex',
  alignItems: 'center',
  padding: 24,
  borderRadius: 24,
}));

const WispContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 700,
  height: 700,
  overflow: 'visible',
  pointerEvents: 'none',
  mixBlendMode: 'multiply',
  zIndex: -1,
}));

const RejectButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 12,
}));

const AcceptButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderRadius: 100,
  width: '109px',
  height: '36px',
}));

interface AgentOutroProps {
  onClick?: () => void;
  isOpen: boolean;
  textContent: string;
  onCloseConfirmationModal: () => void;
  onAcceptDecision: () => void;
}

const AgentOutro: React.FC<AgentOutroProps> = ({
  onClick,
  isOpen,
  textContent,
  onCloseConfirmationModal,
  onAcceptDecision,
}) => {
  const { exit, setWispSize, wispSize } = useAilyAgent();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const refs = useRef(new Array(1).fill(null));
  const { state } = useSlideshow();
  const [refsReady, setRefsReady] = useState(false);
  const { audioEnabled } = state;

  // Function to handle setting of refs
  const setRef = (node: HTMLElement | SVGSVGElement | null, index: number) => {
    refs.current[index] = node;
    // Check if all refs are set
    if (refs.current.every((ref) => ref !== null) && !refsReady) {
      setRefsReady(true); // Set flag to prevent re-triggering
    }
  };

  useEffect(() => {
    if (!refsReady) {
      return;
    }

    if (wispSize.height !== 700 && wispSize.width !== 700) {
      setWispSize(700, 700);
    }

    return () => {
      exit();

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [refsReady, audioEnabled]);

  return (
    <ClosableModal
      ContentBackgroundProps={{ sx: { background: 'black' } }}
      CloseButtonProps={{ sx: { top: 10, fontSize: '28px' } }}
      open={isOpen}
      onClose={onCloseConfirmationModal}
    >
      <Box
        onClick={() => onClick?.()}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        flexDirection="column"
        position="relative"
      >
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <WispContainer>
            <Box
              sx={{ width: '1px', height: '1px' }}
              ref={(node) => setRef(node as HTMLElement | SVGSVGElement | null, 0)}
            />
          </WispContainer>
          <StyledTextContainer>
            <Typography variant="h7" lineHeight="29px" textAlign="center">
              {textContent}
            </Typography>
          </StyledTextContainer>
        </Box>
        {/* marginTop is half the size of the wisp's height, bcs wisp is centered and absolute */}
        <Box marginTop="350px">
          <Stack direction={'row'} gap={6}>
            <RejectButton onClick={() => onCloseConfirmationModal()}>
              <Typography variant="smallBold" fontWeight={860}>
                RETREAT!
              </Typography>
            </RejectButton>
            <AcceptButton onClick={() => onAcceptDecision()}>
              <Typography variant="smallBold" fontWeight={860}>
                GOT IT! →
              </Typography>
            </AcceptButton>
          </Stack>
        </Box>
      </Box>
    </ClosableModal>
  );
};

export default AgentOutro;
