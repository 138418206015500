import { Box, BoxProps, styled } from '@mui/material';

import { useSafariOverflow, useWindowResize } from '../../hooks';

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'block',
  // For the ScaleBox to work properly
  overflow: 'hidden',
  margin: '0 auto',
  width: '100%',
  minHeight: '100vh',
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(0, 5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 7.5),
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 986,
    padding: 0,
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 1100,
  },
  [theme.breakpoints.up('xxl')]: {
    maxWidth: 1400,
  },
}));

export const HomePageScaleBox = styled(Box)(({ theme }) => ({
  transformOrigin: 'top center',
  transform: 'translateZ(0)',
  willChange: 'transform',
  [theme.breakpoints.up('lg')]: {
    transform: 'scale(var(--vh-scale-lg)) translateZ(0)',
    marginBottom: 'calc((var(--vh-scale-lg) - 1) * 100%)',
  },
  [theme.breakpoints.up('xl')]: {
    transform: 'scale(var(--vh-scale-xl)) translateZ(0)',
    marginBottom: 'calc((var(--vh-scale-xl) - 1) * 100%)',
  },
  [theme.breakpoints.up('xxl')]: {
    transform: 'scale(var(--vh-scale-xxl)) translateZ(0)',
    marginBottom: 'calc((var(--vh-scale-xxl) - 1) * 100%)',
  },
}));

export const HomePageContainer = ({ children, ...rest }: BoxProps) => {
  useWindowResize();
  useSafariOverflow();

  return (
    <StyledBox {...rest}>
      <HomePageScaleBox>{children}</HomePageScaleBox>
    </StyledBox>
  );
};
