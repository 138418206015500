import { EventTrackingConfig, withEventTracking } from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import { Color } from '@aily/graphql-sdk/schema';
import { Grid2 as Grid, Skeleton, Stack, styled } from '@mui/material';
import React, { ReactElement, useCallback } from 'react';

import { useHandleLink } from '../../hooks';
import { useModule } from '../../providers';
import { mapColorEnumToStatus, mapModuleCodeToLogoVariant } from '../../utils';
import { ModuleLogo } from '../ModuleLogo';
import { RadialGauge, RadialGaugeProps } from '../RadialGauge';
import { TaglineLogo } from '../TaglineLogo';
import { RenderDialFunction, RenderDialSkeletonFunction } from './types';

const DialsContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4, 0),
  },
  [theme.breakpoints.up('xxl')]: {
    alignItems: 'center',
  },
}));

const maptDialAbstractTypeToDialItem = (
  item: DialAbstractType,
): T.DialItem & { scoreId?: number } => {
  return {
    actualText: item.actualText ?? null,
    label: item.label ?? '',
    link: item.link ?? null,
    progress: item.progress ?? 0,
    progressText: item.progressText ?? null,
    sentiment: item.sentiment ?? T.Color.Green,
    showTarget: item.showTarget ?? false,
    target: item.target ?? null,
    targetText: item.targetText ?? null,
    varianceText: item.varianceText ?? null,
    varianceAIText: item.varianceAIText ?? null,
    scoreId: item.scoreId ?? undefined,
    __typename: 'DialItem',
  };
};

export interface DialAbstractType {
  actualText?: T.TextResult | null;
  label?: string | null;
  link?: T.TextLinkResult | null;
  progress?: number | null;
  progressText?: T.TextResult | null;
  sentiment?: Color | null;
  showTarget?: boolean | null;
  target?: number | null;
  scoreId?: number | null;
  targetText?: T.TextResult | null;
  varianceText?: T.TextResult | null;
  varianceAIText?: T.TextResult | null;
}

export interface DialsDataViewAbstractType {
  annotation?: string | null;
  annotationDate?: string | null;
  items?: (DialAbstractType | null)[] | null;
}

export interface DefaultDialsProps {
  dataView?: DialsDataViewAbstractType;
  loading?: boolean;
  onDialRender?: RenderDialFunction;
  onDialSkeletonRender?: RenderDialSkeletonFunction;
  onDialToggle?: (dial: DialAbstractType, index: number, toggled: boolean) => void;
  logo?: ReactElement;
  tagline?: string;
}

const DefaultDials: React.FC<DefaultDialsProps> = ({
  dataView,
  loading,
  onDialRender,
  onDialSkeletonRender,
  onDialToggle,
  logo,
  tagline,
}) => {
  const module = useModule();
  const handleLink = useHandleLink();

  const radialGaugeProps: RadialGaugeProps = {};

  const renderLoadingDials = useCallback(() => {
    const element = onDialSkeletonRender?.();

    if (element) {
      return element;
    }

    return (
      <>
        <RadialGauge {...radialGaugeProps} loading />
        <RadialGauge {...radialGaugeProps} loading />
        <RadialGauge {...radialGaugeProps} loading />
      </>
    );
  }, [radialGaugeProps, onDialSkeletonRender, handleLink]);

  const renderDialItem = useCallback(
    (dial: T.DialItem, index: number) => {
      const {
        progress,
        progressText,
        target,
        targetText,
        actualText,
        varianceText,
        varianceAIText,
        sentiment: color,
        label,
        link,
      } = dial;

      const element = onDialRender?.(dial, index);
      if (element) {
        return element;
      }

      const mapColorToVarianceStatus = (color: T.Color): 'success' | 'error' => {
        const status = mapColorEnumToStatus(color);
        return status === 'success' ? 'success' : 'error';
      };

      return (
        <RadialGauge
          key={index}
          {...radialGaugeProps}
          progress={progress ?? undefined}
          progressText={progressText?.value ?? undefined}
          target={target ?? undefined}
          targetText={targetText?.value ?? undefined}
          color={color ? mapColorEnumToStatus(color) : undefined}
          varianceColor={color ? mapColorToVarianceStatus(color) : undefined}
          label={label ?? ''}
          LabelTypographyProps={
            link?.linkResult
              ? {
                  style: { cursor: 'pointer' },
                  onClick: () => handleLink(link.linkResult as T.Link),
                }
              : undefined
          }
          actualText={actualText?.value ?? undefined}
          varianceText={varianceText?.value ?? undefined}
          varianceAIText={varianceAIText?.value ?? undefined}
          varianceTextColor={
            varianceText?.color?.color
              ? mapColorEnumToStatus(varianceText.color.color as T.Color)
              : undefined
          }
          varianceAITextColor={
            varianceAIText?.color?.color
              ? mapColorEnumToStatus(varianceAIText.color.color as T.Color)
              : undefined
          }
        />
      );
    },
    [radialGaugeProps, onDialRender, onDialToggle],
  );

  return (
    <StyledGrid container justifyContent="center" alignItems="center" data-testid="Dials">
      <Grid size={{ xs: 12, md: 5 }}>
        <TaglineLogo
          logo={
            logo ? (
              logo
            ) : (
              <ModuleLogo
                variant={mapModuleCodeToLogoVariant(module?.moduleCode ?? T.ModuleCode.Financial)}
              />
            )
          }
          tagline={tagline ?? 'Make. Today. Count.'}
          annotation={
            loading ? (
              <Skeleton variant="text" sx={{ width: 200, height: 14, mt: 0 }} />
            ) : (
              `${dataView?.annotation} (${dataView?.annotationDate})`
            )
          }
        />
      </Grid>
      <Grid size={{ xs: 12, md: 7 }} sx={{ py: { xs: 5, md: 0 } }}>
        <DialsContainer
          direction="row"
          spacing={{ xs: 5, xl: 5.5, xxl: 7.125 }}
          data-testid="Dials"
        >
          {loading
            ? renderLoadingDials()
            : dataView?.items?.map((dial, index) => {
                if (!dial) {
                  return null;
                }

                return renderDialItem(maptDialAbstractTypeToDialItem(dial), index);
              })}
        </DialsContainer>
      </Grid>
    </StyledGrid>
  );
};

const trackingConfig: EventTrackingConfig<DefaultDialsProps> = {
  onDialToggle: {
    eventName: 'dial.toggled',
    getEventProps: (dial, index) => ({
      component: 'dial',
      component_value: index.toString() ?? '',
      name: dial.label ?? '',
      intent: 'toggle',
      item_type: 'text',
      event_version: '1.0.0',
    }),
  },
};

const TrackedDefaultDials = withEventTracking(DefaultDials, trackingConfig);

export { TrackedDefaultDials as DefaultDials };
