import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import {
  AiIcon,
  ButtonBase,
  ButtonBaseProps,
  IconWrapper,
  Stack,
  Typography,
} from '../../../../../components';
import AgentGlow from './AgentGlow';

export interface AgentCTAButtonProps extends ButtonBaseProps {
  /**
   * The button style variant.
   */
  variant?: AgentCTAButtonVariant;
  /**
   * Whether to show the icon.
   */
  showIcon?: boolean;
  /**
   * Text displayed before the label (children).
   */
  prefix?: string;
  /**
   * Custom styles for the button.
   */
  sx?: SxProps<Theme>;
}

export type AgentCTAButtonVariant = 'action' | 'context';

const AgentCTAButtonRoot = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<AgentCTAButtonProps>(({ theme, variant = 'action' }) => ({
  gap: theme.tokens.spacing['xsmall'],
  borderRadius: 40, // Missing border radius token
  minWidth: 311,
  height: 52,
  padding: theme.spacing(theme.tokens.spacing['xxsmall'], theme.tokens.spacing['medium']),

  ...theme.tokens.typography['heading.button'],
  lineHeight: 1,

  // Variant styles
  ...(variant === 'action' && {
    boxShadow: `-1px 1px 2px 0px ${theme.tokens.color['neutral.400']} inset`,
    backgroundColor: theme.tokens.color['text.regular'],
    color: theme.tokens.color['background.regular'],
  }),

  ...(variant === 'context' && {
    overflow: 'hidden', // To cut the glow
    boxShadow: '-1px 1px 2px 0px rgba(255, 255, 255, 0.75) inset', // Missing color token
    backgroundColor: theme.tokens.color['neutral.900'],
    color: theme.tokens.color['text.regular'],
    position: 'relative',
  }),
}));

const AgentGlowWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: -1,
});

const AgentCTAButton: React.FC<AgentCTAButtonProps> = ({
  children,
  variant,
  showIcon,
  prefix,
  ...rest
}) => (
  <AgentCTAButtonRoot variant={variant} data-testid="agent-cta-button" {...rest}>
    {variant === 'context' && (
      <AgentGlowWrapper>
        <AgentGlow />
      </AgentGlowWrapper>
    )}
    {showIcon && (
      <IconWrapper data-testid="agent-cta-button__icon">
        <AiIcon
          size="icon.medium-large"
          textColor={variant === 'action' ? 'background.regular' : undefined}
        />
      </IconWrapper>
    )}
    <Stack direction="row" alignItems="center" spacing="xxsmall">
      {prefix && (
        <Typography
          variant="inherit"
          color="sentiment.neutral"
          sx={{ lineHeight: 1 }}
          data-testid="agent-cta-button__prefix"
        >
          {prefix}
        </Typography>
      )}
      {children}
    </Stack>
  </AgentCTAButtonRoot>
);

export default AgentCTAButton;
