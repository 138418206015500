import { styled } from '@mui/material';
import React from 'react';

import { Button } from '../../buttons';
import { IconName, icons, SvgIconProps } from '../../icons';
import { Stack } from '../../Stack';
import { Typography } from '../../Typography';

export type StatusMessageProps = {
  title: string;
  description: string;
  icon: IconName;
  iconColor?: SvgIconProps['color'];
} & (
  | { primaryButtonLabel: string; onPrimaryButtonClick: () => void }
  | { primaryButtonLabel?: never; onPrimaryButtonClick?: never }
) &
  (
    | { secondaryButtonLabel: string; onSecondaryButtonClick: () => void }
    | { secondaryButtonLabel?: never; onSecondaryButtonClick?: never }
  );

const StatusMessageRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.tokens.spacing['xxlarge'],
}));

const StatusMessage: React.FC<StatusMessageProps> = ({
  title,
  description,
  icon,
  iconColor = 'sentiment.warning',
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
}) => {
  const IconComponent = icons[icon];
  const primaryButton = !!primaryButtonLabel && !!onPrimaryButtonClick;
  const secondaryButton = !!secondaryButtonLabel && !!onSecondaryButtonClick;

  return (
    <StatusMessageRoot data-testid="status-message">
      <IconComponent size="icon.large" color={iconColor} data-testid="status-message__icon" />
      <Stack direction="column" spacing="xsmall" alignItems="center">
        <Typography
          variant="heading.H9"
          color="text.regular"
          align="center"
          data-testid="status-message__title"
        >
          {title}
        </Typography>
        <Typography
          variant="body.regular-condensed"
          color="text.subtle"
          align="center"
          sx={{ whiteSpace: 'pre-wrap' }}
          data-testid="status-message__description"
        >
          {description}
        </Typography>
      </Stack>
      {(primaryButton || secondaryButton) && (
        <Stack
          direction="row"
          spacing="small"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          {secondaryButton && (
            <Button
              variant="secondary"
              onClick={onSecondaryButtonClick}
              data-testid="status-message__secondary-button"
            >
              {secondaryButtonLabel}
            </Button>
          )}
          {primaryButton && (
            <Button
              variant="primary"
              onClick={onPrimaryButtonClick}
              data-testid="status-message__primary-button"
            >
              {primaryButtonLabel}
            </Button>
          )}
        </Stack>
      )}
    </StatusMessageRoot>
  );
};

export default StatusMessage;
