import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

export interface RecommenderCardContentProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const RecommenderCardContentRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  height: '100%',
  borderRadius: 'inherit',
  padding: theme.tokens.spacing['small'],
  paddingTop: theme.tokens.spacing['medium'],
}));

const RecommenderCardContent: React.FC<RecommenderCardContentProps> = ({ children, ...rest }) => (
  <RecommenderCardContentRoot {...rest}>{children}</RecommenderCardContentRoot>
);

export default RecommenderCardContent;
