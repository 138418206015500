import { CSSProperties } from 'react';

/**
 * Returns CSS properties to limit text to a specified number of lines with ellipsis.
 *
 * @param lineClamp - Number of lines to display before truncating.
 * @returns CSS properties for single or multi-line clamping.
 */
export function getLineClampStyles(lineClamp?: number): CSSProperties {
  if (lineClamp === 1) {
    // Single-line truncation with ellipsis
    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };
  }

  if (lineClamp && lineClamp > 1) {
    // Multi-line truncation with fallback
    return {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: lineClamp,
      textOverflow: 'ellipsis', // Fallback for non-supporting browsers
      wordBreak: 'break-word',
    };
  }

  return {};
}
