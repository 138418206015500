import * as T from '@aily/graphql-sdk/schema';
import { mapSentimentToColor, useSlideshow } from '@aily/saas-core';
import { HStack } from '@aily-labs/ui';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { map } from 'lodash-es';
import React, { useMemo } from 'react';

import { AiActive } from '../../../../icons';
import theme from '../../../../theme/default';
import {
  AgentJsonData,
  ElementType,
  ExtendedTextElement,
  Popup as PopupType,
  Screen,
} from '../../types';
import { capitalizeFirstAndRemoveLastPeriod, lowercaseFirstLetter } from '../../utils';
import { ScreenPopupComponent } from '../AgentSlideshow';
import { PopupButton, PopupButtonProps } from '../PopupButton';

const renderTextElement = (element: ExtendedTextElement): JSX.Element | string => {
  const { content } = element;
  if (element?.sentiment?.toUpperCase() === T.Sentiment.Neutral) {
    return lowercaseFirstLetter(content as string);
  }

  return (
    <Typography
      component="span"
      fontSize={24}
      fontWeight={590}
      data-testid="agent-text-element"
      sx={{
        lineHeight: '1.2',
        color: mapSentimentToColor(
          // @ts-expect-error reason why this can't be properly typed now is that we're missing the type from the AI team that will include all options
          element?.display_icon ? T.Sentiment.Neutral : element?.sentiment?.toUpperCase(),
        ),
      }}
    >
      &nbsp;{content as string}&nbsp;
    </Typography>
  );
};

const extractTextElements = (
  screen: Screen,
  popupsArr: PopupType[],
  decision: T.StoryDecision | null,
) => {
  const textElements: Array<JSX.Element | string> = [];
  const popupList: PopupType[] = popupsArr;

  const extractTextFromElements = (elements: ExtendedTextElement[][][]) => {
    elements.forEach((elementGroup) => {
      elementGroup.forEach((elementSubGroup) => {
        elementSubGroup.forEach((element) => {
          if (element.type === ElementType.Text) {
            textElements.push(renderTextElement(element));
          }
        });
      });
    });
  };

  screen.content.forEach((contentItem) => {
    extractTextFromElements(contentItem.elements);
  });

  if (screen.popup) {
    popupList.push(screen.popup);
  }

  const uniquePopupList = Array.from(new Set(popupList.map((popup) => JSON.stringify(popup)))).map(
    (popup) => JSON.parse(popup),
  );

  // check if popup screens should be rendered as they are omitted on initial extraction and there can be
  // a case where a same screen with a popup, can have screens in the popup that shouldn't be rendered
  const filteredPopupArray = uniquePopupList.map((popup) => {
    const filteredPopupScreens = popup.screens
      .filter((popupScreen: Screen) => decision?.screenIds.includes(popupScreen.screen_id))
      .map((popupScreen: Screen) => ({ renderPopupScreen: true, ...popupScreen }));

    return { ...popup, screens: filteredPopupScreens };
  });

  return { textElements, popups: decision ? filteredPopupArray : uniquePopupList };
};

const ScreenPopupButtonComponent: React.FC<
  Omit<PopupButtonProps, 'children'> & { popup: PopupType }
> = ({ popup, ...rest }) => {
  if (!popup || !popup.button_title) return null;

  const { button_logo, button_title } = popup;
  return (
    <PopupButton prefix={button_logo} {...rest}>
      {button_title}
    </PopupButton>
  );
};

export interface AgentHeaderProps extends StackProps {
  subTitle?: string;
  titleScreen: Screen;
  agentJsonData: AgentJsonData;
  baseAudioURL: string;
  decision: T.StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
  headerPopupButtons?: any;
  introGroupExists: boolean;
}

export const AgentDefaultHeader: React.FC<AgentHeaderProps> = ({
  titleScreen,
  agentJsonData,
  baseAudioURL,
  headerPopupButtons,
  decision,
  onAgentClose,
  onBackdropClose,
  introGroupExists,
}) => {
  const { textElements, popups } = extractTextElements(titleScreen, headerPopupButtons, decision);
  const { state } = useSlideshow();

  const memoisedPopups = useMemo(
    () => (
      <>
        {popups.map((popup, index) => {
          return (
            <React.Fragment key={popup.button_title}>
              <ScreenPopupButtonComponent
                popup={popup}
                PopupComponent={(props) => (
                  <ScreenPopupComponent
                    {...props}
                    title={buildTitleComponent(popup)}
                    screens={popup.screens}
                    baseAudioURL={baseAudioURL}
                    agentJsonData={agentJsonData}
                    decision={decision}
                    onAgentClose={onAgentClose}
                    onBackdropClose={onBackdropClose}
                  />
                )}
                sx={{
                  mt: 1,
                }}
              />
              {index < popups.length - 1 && (
                <Box component="span" sx={{ mx: 1 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                  >
                    <circle cx="2" cy="2" r="2" fill={theme.palette.grey[200]} />
                  </svg>
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </>
    ),
    [popups],
  );

  // if intro exists on agent, prevent rendering the header
  if (introGroupExists && state.currentGroup === 0) {
    return null;
  }

  return (
    <Stack data-testid="header-content" alignItems="center" justifyContent="center">
      <HStack width={1000} alignItems="center">
        <AiActive sx={{ fontSize: 40, paddingRight: 1 }} />
        <Typography fontSize={24} fontWeight={590} component="p" sx={{ lineHeight: '1.5' }}>
          {map(capitalizeFirstAndRemoveLastPeriod(textElements), (element, index) => {
            return <span key={`header-el-${index}`}>{element}</span>;
          })}
        </Typography>
      </HStack>
      <HStack zIndex={200} gap={16} justifyContent="center" alignItems="center">
        {memoisedPopups}
      </HStack>
    </Stack>
  );
};

// @ts-expect-error due to time constraints, this remains untyped
const buildTitleComponent = (popup) => {
  return (
    <Typography variant="h5" fontWeight={590}>
      <Box display="flex" flexDirection="row" gap={1}>
        <Typography variant="inherit" sx={{ color: theme.palette.primary.main }}>
          {popup.screens?.[0].module_id?.[0] ?? ''}
        </Typography>
        <Typography variant="inherit">{popup.button_title ?? ''}</Typography>
      </Box>
    </Typography>
  );
};
