import { reportGraphQLErrors, reportNetworkError } from '@aily/monitoring-service';
import { onError } from '@apollo/client/link/error';

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    reportGraphQLErrors(graphQLErrors, operation);
  }

  if (networkError) {
    console.error(`[Network error]:`, networkError);

    reportNetworkError(networkError);
  }
});
