import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material/styles';
import React, { useId } from 'react';

import { useTokenValue } from '../../../../hooks';
import { isLinearGradient, parseLinearGradient } from '../../../../utils';
import { RecommenderCardSize } from './RecommenderCard';
import { useRecommenderCardContext } from './RecommenderCardContext';

export interface RecommenderCardCheckedMarkProps {
  color: ColorTokenKey;
  opacity?: number;
}

interface RecommenderCardCheckedMarkSizeProps {
  size: number;
}

const sizeConfig: Record<RecommenderCardSize, RecommenderCardCheckedMarkSizeProps> = {
  default: { size: 40 },
  large: { size: 50 },
};

const RecommenderCardCheckedMarkRoot = styled('svg')({
  position: 'absolute',
  // 1px negative offset fixes subpixel rendering issues
  right: -1,
  bottom: -1,
  pointerEvents: 'none',
});

const RecommenderCardCheckedMark: React.FC<RecommenderCardCheckedMarkProps> = ({
  color,
  opacity = 0.4,
}) => {
  const id = useId();
  const colorValue = useTokenValue('color', color);
  const gradient = isLinearGradient(colorValue) ? parseLinearGradient(colorValue) : null;
  const context = useRecommenderCardContext();
  const cardSize = context?.size ?? 'default';
  const { size } = sizeConfig[cardSize];

  return (
    <RecommenderCardCheckedMarkRoot
      width={size}
      height={size}
      viewBox="0 0 40 40"
      data-testid="recommender-card__checked-mark"
    >
      {gradient && (
        <defs>
          <linearGradient id={`${id}-gradient`} gradientTransform={gradient.gradientTransform}>
            {gradient.stops.map((stop, index) => (
              <stop key={index} offset={stop.offset} stopColor={stop.color} />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        opacity={opacity}
        d="M40 40L40 0L0 40Z"
        fill={gradient ? `url(#${id}-gradient)` : color}
      />
      <path
        d="M35.8597 22H35.0031C34.883 22 34.769 22.0559 34.6955 22.1516L29.643 28.6413L27.2203 25.5288C27.1836 25.4816 27.1369 25.4434 27.0837 25.4172C27.0304 25.3909 26.9719 25.3773 26.9127 25.3772H26.0561C25.974 25.3772 25.9287 25.4729 25.9789 25.5375L29.3354 29.849C29.4923 30.0503 29.7937 30.0503 29.9518 29.849L35.9369 22.159C35.9871 22.0957 35.9418 22 35.8597 22Z"
        fill="white"
      />
    </RecommenderCardCheckedMarkRoot>
  );
};

export default RecommenderCardCheckedMark;
