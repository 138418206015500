import { alpha, Box, styled, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { useSlideshow } from '../../../../components';
import { useEnvironment } from '../../../../providers';
import { AilyAgentOperation } from '../../classes';
import { useAilyAgent } from '../../providers';
import { ElementType, Screen } from '../../types/agentJsonData';
import { getAgentAudioPath } from '../../utils/getAgentAudioPath';
import { AgentContentRenderer } from '../AgentContentRenderer/AgentContentRenderer';

const StyledTextContainer = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  display: 'flex',
  alignItems: 'center',
  padding: 24,
  borderRadius: 24,
}));

const WispContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 700,
  height: 700,
  overflow: 'visible',
  pointerEvents: 'none',
  mixBlendMode: 'multiply',
  zIndex: -1,
}));

interface AgentIntroProps {
  screen: Screen;
}

const AgentIntro: React.FC<AgentIntroProps> = ({ screen }) => {
  const { startOperations, exit, setWispSize, wispSize } = useAilyAgent();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { API_URL, ENV, TENANT } = useEnvironment();
  const parsedUrl = new URL(API_URL);
  const baseAudioURL = `${parsedUrl.protocol}//${ENV}-mobile-bff.${TENANT}.aily-app.com/api/file/get-image-file`;
  const { state } = useSlideshow();
  const { audioEnabled } = state;
  const refs = useRef(new Array(1).fill(null));
  const [refsReady, setRefsReady] = useState(false);

  // Function to handle setting of refs
  const setRef = (node: HTMLElement | SVGSVGElement | null, index: number) => {
    refs.current[index] = node;
    // Check if all refs are set
    if (refs.current.every((ref) => ref !== null) && !refsReady) {
      setRefsReady(true); // Set flag to prevent re-triggering
    }
  };

  useEffect(() => {
    if (!refsReady || !audioEnabled) {
      return;
    }

    if (wispSize.height !== 700 && wispSize.width !== 700) {
      setWispSize(700, 700);
    }

    const operations = refs.current.reduce((acc, ref) => {
      if (screen?.audio_track) {
        acc.push({
          audioPath: getAgentAudioPath(baseAudioURL, screen.audio_track),
          elementRef: ref,
          delayAfterEnd: 1000,
          corner: 'top-right',
        });
      }

      return acc;
    }, [] as AilyAgentOperation[]);

    if (operations.length) {
      exit();
      startOperations(operations);
    }

    return () => {
      exit();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [
    refsReady,
    audioEnabled,
    baseAudioURL,
    exit,
    screen.audio_track,
    setWispSize,
    startOperations,
  ]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
      <WispContainer>
        <Box
          sx={{ width: '1px', height: '1px' }}
          ref={(node) => setRef(node as HTMLElement | SVGSVGElement | null, 0)}
        />
      </WispContainer>
      <AgentContentRenderer content={screen.content}>
        {(elements) =>
          elements.map((element, elementKey) => (
            <Box key={elementKey}>
              {element.type === ElementType.Text && (
                <StyledTextContainer>
                  <Typography variant="h7" lineHeight="29px" textAlign="center">
                    {element.content}
                  </Typography>
                </StyledTextContainer>
              )}
            </Box>
          ))
        }
      </AgentContentRenderer>
    </Box>
  );
};

export default AgentIntro;
