import React, { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react';

import { AilyAgent, AilyAgentOperation } from '../classes';
import { WispSvg } from '../components/Wisp';

type WispProps = {
  width?: number;
  height?: number;
};

interface AilyAgentContextType {
  startOperations: (operations: AilyAgentOperation[]) => void;
  exit: () => void;
  setWispSize: (width: number, height: number) => void;
  wispSize: WispProps;
}

const AilyAgentContext = createContext<AilyAgentContextType | undefined>(undefined);

export const AilyAgentProvider = ({ children }: { children: ReactNode }) => {
  const agentRef = useRef(AilyAgent.getInstance());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [wispSize, setWispSizeState] = useState<WispProps>({});

  // Callback ref to handle SVG element
  const handleSvgRef = useCallback((svgElement: SVGSVGElement | null) => {
    if (svgElement) {
      agentRef.current.setSvgElement(svgElement, '.body__glow ellipse');
    }
  }, []);

  const startOperations = (operations: AilyAgentOperation[]) => {
    agentRef.current.exit();
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      agentRef.current.setOperations(operations);
      agentRef.current.start();
    }, 500);
  };

  const exit = () => {
    agentRef.current.exit();
  };

  const setWispSize = (width: number, height: number) => {
    setWispSizeState({ width, height });
  };

  const props = {
    ...(wispSize.width && { width: wispSize.width }),
    ...(wispSize.height && { height: wispSize.height }),
  };

  return (
    <AilyAgentContext.Provider value={{ startOperations, exit, setWispSize, wispSize }}>
      <WispSvg ref={handleSvgRef} {...props} />
      {children}
    </AilyAgentContext.Provider>
  );
};

export const useAilyAgent = (): AilyAgentContextType => {
  const context = useContext(AilyAgentContext);

  if (!context) {
    throw new Error('useAilyAgent must be used within an AilyAgentProvider');
  }

  return context;
};
