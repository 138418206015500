import { CSSProperties } from 'react';

/**
 * Type for a single gradient color stop.
 */
export interface GradientColorStop {
  offset: string;
  color: string;
}

/**
 * Type for parsed linear-gradient.
 */
export interface ParsedLinearGradient {
  type: 'linearGradient';
  gradientTransform: string;
  stops: GradientColorStop[];
}

/**
 * Check if a string is a CSS linear-gradient.
 * @param {string} color - The color string to check.
 * @returns {boolean} True if the string is a linear-gradient, false otherwise.
 */
export const isLinearGradient = (color: string): boolean => color.startsWith('linear-gradient');

/**
 * Parse a CSS linear-gradient string into an SVG-compatible gradient object.
 * @param {string} linearGradient - The CSS linear-gradient string.
 * @returns {ParsedLinearGradient} An object containing SVG gradient attributes and stops.
 */
export const parseLinearGradient = (linearGradient: string): ParsedLinearGradient => {
  const linearGradientRegex = /^linear-gradient\((.+)\)$/i;
  const match = linearGradient.match(linearGradientRegex);

  if (!match) {
    console.warn('Invalid CSS linear-gradient syntax. Defaulting to top-to-bottom gradient.');
    return {
      type: 'linearGradient',
      gradientTransform: `rotate(90, 0.5, 0.5)`, // Default top-to-bottom
      stops: [],
    };
  }

  let angle = 180; // Default to top-to-bottom
  const colorStops: GradientColorStop[] = [];

  // Extract the argument string inside linear-gradient(...)
  const rawArgs = match[1].trim();

  // Split arguments using regex to handle commas outside parentheses
  const args = rawArgs.split(/,(?![^()]*\))/).map((arg) => arg.trim());

  // Check if the first argument is an angle
  if (args[0] && /^\d+(deg|rad|grad|turn)$/.test(args[0])) {
    angle = convertAngleToDegrees(args.shift() as string);
  }

  // Parse color stops
  args.forEach((stop) => {
    const parts = stop.match(/(.*?)(\s+\d+%|\s+\d*%?)?$/);
    if (parts) {
      const color = parts[1].trim();
      const offset = parts[2]?.trim() || ''; // Default to no explicit offset
      colorStops.push({ offset, color });
    }
  });

  // Normalize offsets
  const normalizedStops = normalizeOffsets(colorStops);

  // Adjust CSS angle to SVG gradientTransform angle
  const svgAngle = angle - 90;

  return {
    type: 'linearGradient',
    gradientTransform: `rotate(${svgAngle}, 0.5, 0.5)`,
    stops: normalizedStops,
  };
};

/**
 * Convert a CSS angle (deg, rad, grad, turn) to degrees.
 * @param {string} angle - The CSS angle string.
 * @returns {number} The angle in degrees.
 */
export const convertAngleToDegrees = (angle: string): number => {
  const match = angle.trim().match(/^([\d.]+)(deg|rad|grad|turn)$/);
  if (!match) {
    console.warn(`Invalid angle format: ${angle}, defaulting to 0 degrees.`);
    return 0; // Default to 0 degrees for invalid input
  }

  const [, value, unit] = match;
  const numericValue = parseFloat(value);

  switch (unit) {
    case 'deg':
      return numericValue;
    case 'rad':
      return (numericValue * 180) / Math.PI;
    case 'grad':
      return numericValue * 0.9;
    case 'turn':
      return numericValue * 360;
    default:
      console.warn(`Unexpected angle unit: ${unit}, defaulting to 0 degrees.`);
      return 0;
  }
};

/**
 * Normalize the offsets of the color stops for SVG if missing.
 * @param {GradientColorStop[]} colorStops - Array of color stops.
 * @returns {GradientColorStop[]} The normalized color stops.
 */
export const normalizeOffsets = (colorStops: GradientColorStop[]): GradientColorStop[] => {
  const stopsWithoutOffset = colorStops.filter((stop) => !stop.offset);
  if (stopsWithoutOffset.length > 0) {
    const step = 100 / (colorStops.length - 1);
    return colorStops.map((stop, index) => ({
      ...stop,
      offset: stop.offset || `${index * step}%`,
    }));
  }
  return colorStops;
};

/**
 * Returns styles for text color or gradient.
 *
 * @param color - Color or gradient string.
 * @returns CSS properties for text color.
 */
export const getTextColorStyles = (color?: string): CSSProperties => {
  return color && isLinearGradient(color)
    ? {
        background: color,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }
    : {
        color,
      };
};
