import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const TrendArrowUpThickIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      d="M26.0103 9.95181C26.9478 11.3828 27.5588 13.003 27.7928 14.7009C28.0969 16.9088 27.7508 19.1559 26.7954 21.177C25.8399 23.1981 24.3149 24.9088 22.4005 26.1071C20.4861 27.3055 18.2622 27.9413 15.9913 27.9397C13.7205 27.9381 11.4975 27.299 9.58486 26.098C7.67223 24.8969 6.14981 23.184 5.19734 21.1616C4.24487 19.1391 3.90213 16.8915 4.20957 14.6841C4.44602 12.9864 5.05943 11.3671 5.99908 9.93748C6.09238 9.79553 6.1889 9.65545 6.28858 9.51736C6.31934 9.47474 6.34687 9.43057 6.37114 9.38522C6.61133 8.9365 6.53365 8.3715 6.13233 8.03687C5.69046 7.66843 5.02426 7.71909 4.68288 8.179C4.64651 8.22799 4.61049 8.27721 4.57481 8.32664C4.4501 8.49941 4.3296 8.67481 4.21338 8.8527C3.11968 10.5268 2.40533 12.4205 2.12891 14.4051C1.76721 17.0021 2.17044 19.6463 3.29099 22.0257C4.41154 24.4051 6.20263 26.4203 8.45278 27.8333C10.7029 29.2462 13.3182 29.9981 15.9898 30C18.6614 30.0019 21.2778 29.2538 23.53 27.844C25.7822 26.4343 27.5763 24.4217 28.7004 22.0439C29.8245 19.6661 30.2317 17.0225 29.8738 14.425C29.6004 12.4399 28.8888 10.5452 27.7976 8.86959C27.6817 8.69154 27.5614 8.51596 27.437 8.34301C27.4014 8.29353 27.3654 8.24426 27.3291 8.19522C26.9884 7.73482 26.3223 7.68321 25.8799 8.05102C25.4781 8.38506 25.3996 8.94995 25.6391 9.39902C25.6633 9.44441 25.6907 9.48861 25.7214 9.53127C25.8209 9.66951 25.9172 9.80973 26.0103 9.95181Z"
      fill="white"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M8.43959 6.95957C9.26316 6.26886 10.1738 5.69231 11.1487 5.24688C12.6768 4.54871 14.3292 4.18808 16 4.18808C17.6708 4.18808 19.3231 4.54871 20.8513 5.24688C21.8262 5.69231 22.7368 6.26887 23.5604 6.95958C23.7545 7.12237 23.9438 7.2915 24.1279 7.46676C24.1674 7.50433 24.2089 7.53877 24.252 7.57001C24.6784 7.87909 25.2642 7.87545 25.657 7.50614C26.0894 7.09949 26.1236 6.40417 25.6988 5.98899C25.6536 5.94476 25.608 5.90086 25.5622 5.85727C25.3437 5.64929 25.1191 5.44863 24.8886 5.25557C23.9213 4.44519 22.852 3.7686 21.7074 3.24564C19.9096 2.42427 17.9656 2 16 2C14.0344 2 12.0904 2.42427 10.2926 3.24564C9.14801 3.7686 8.07871 4.44519 7.11139 5.25556C6.88094 5.44863 6.65627 5.64928 6.43775 5.85726C6.39195 5.90085 6.34643 5.94476 6.30117 5.98899C5.87639 6.40417 5.91057 7.09949 6.34301 7.50613C6.73574 7.87545 7.32161 7.87909 7.74802 7.57001C7.79113 7.53877 7.8326 7.50433 7.87209 7.46675C8.05622 7.2915 8.24549 7.12237 8.43959 6.95957Z"
      fill="#48ff9b"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M16.563 9.67493C16.4111 9.52237 16.2029 9.44275 15.992 9.4471C15.7849 9.44284 15.5761 9.51912 15.4211 9.67491L13.006 12.1012C12.7118 12.3968 12.7214 12.8668 13.0276 13.1509C13.3338 13.4351 13.8206 13.4257 14.1148 13.1301L15.2397 12V21.155C15.2397 21.565 15.5839 21.8974 16.0086 21.8974C16.4333 21.8974 16.7775 21.565 16.7775 21.155V12.0333L17.8692 13.1301C18.1635 13.4257 18.6503 13.4351 18.9564 13.151C19.2626 12.8668 19.2723 12.3969 18.978 12.1012L16.563 9.67493Z"
      fill="#48ff9b"
    />
  </SvgIcon>
);

export default TrendArrowUpThickIcon;
