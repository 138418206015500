import * as T from '@aily/graphql-sdk/schema';

import { LeaderboardItemSentiment } from '../types';

export function mapLeaderboardItemSentimentToSentiment(
  sentiment: LeaderboardItemSentiment,
): T.Sentiment {
  const defaultMap: Record<LeaderboardItemSentiment, T.Sentiment> = {
    positive: T.Sentiment.Positive,
    negative: T.Sentiment.Negative,
    neutral: T.Sentiment.Neutral,
  };

  return defaultMap[sentiment];
}
