import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const CloseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path d="M18.6487 17L26.9397 7.40184C27.0787 7.24233 26.9618 7 26.7471 7H24.2266C24.0782 7 23.936 7.06442 23.8381 7.17485L17 15.092L10.1619 7.17485C10.0671 7.06442 9.92501 7 9.7734 7H7.25293C7.03815 7 6.92129 7.24233 7.06026 7.40184L15.3513 17L7.06026 26.5982C7.02913 26.6337 7.00916 26.6772 7.00272 26.7234C6.99628 26.7697 7.00363 26.8168 7.02392 26.8591C7.04421 26.9014 7.07657 26.9372 7.11716 26.9621C7.15775 26.9871 7.20488 27.0003 7.25293 27H9.7734C9.92185 27 10.064 26.9356 10.1619 26.8251L17 18.908L23.8381 26.8251C23.9329 26.9356 24.075 27 24.2266 27H26.7471C26.9618 27 27.0787 26.7577 26.9397 26.5982L18.6487 17Z" />
  </SvgIcon>
);

export default CloseIcon;
