import { IconButton, Input, InputAdornment, InputProps, styled } from '@mui/material';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { CloseOutlined } from '../../icons';

export interface InputBoxProps extends Omit<InputProps, 'onChange'> {
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  error?: boolean;
  fixedMaxHeight?: number;
}

const StyledInput = styled(Input)<{ error?: boolean; fixedMaxHeight?: number }>(
  ({ theme, error, multiline, fixedMaxHeight }) => ({
    display: 'flex',
    height: 48,
    borderRadius: 6,
    padding: theme.spacing(1.25, 2),
    backgroundColor: theme.palette.divider,
    ...theme.typography.body,
    ...(multiline && {
      alignItems: 'flex-start',
      resize: 'none',
      overflow: 'scroll',
      maxHeight: fixedMaxHeight ?? 'none',
    }),
    ...(error && { boxShadow: `0 0 0 1px ${theme.palette.error.main}` }),
    '& .MuiInput-input::placeholder': {
      opacity: 1,
      color: theme.palette.text.secondary,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
      fontSize: 28,
    },
    '& .MuiIconButton-root:hover > .MuiSvgIcon-root': {
      color: theme.palette.text.primary,
    },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: theme.spacing(-1),
      ...(multiline && { height: 44 }),
    },
  }),
);

const FadeIn = (props: MotionProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.1 }}
    {...props}
  />
);

export const InputBox = forwardRef<HTMLInputElement, InputBoxProps>(
  ({ value, defaultValue, fixedMaxHeight, onChange, ...rest }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [localValue, setLocalValue] = useState(defaultValue ?? '');
    const currentValue = value !== undefined ? value : localValue;

    const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
      ({ target: { value: newValue } }) => {
        setLocalValue(newValue);
        onChange?.(newValue);
      },
      [onChange],
    );

    const handleClearButtonClick = useCallback(() => {
      setLocalValue('');
      onChange?.('');
      inputRef.current?.focus();
    }, [onChange]);

    const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
      e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
    }, []);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [inputRef]);

    return (
      <StyledInput
        inputRef={inputRef}
        value={currentValue}
        defaultValue={defaultValue}
        fixedMaxHeight={fixedMaxHeight}
        onChange={handleChange}
        disableUnderline
        onFocus={handleFocus}
        endAdornment={
          <AnimatePresence>
            {!!currentValue && (
              <FadeIn>
                <InputAdornment position="end">
                  <IconButton
                    disableRipple
                    onClick={handleClearButtonClick}
                    data-testid="ClearButton"
                  >
                    <CloseOutlined />
                  </IconButton>
                </InputAdornment>
              </FadeIn>
            )}
          </AnimatePresence>
        }
        {...rest}
      />
    );
  },
);
