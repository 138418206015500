import * as T from '@aily/graphql-sdk/schema';
import { Box, Stack, Typography } from '@mui/material';
import React, { FC, memo, useCallback } from 'react';

import { TriangleDown, TriangleUp } from '../../../../icons';
import { colors } from '../../../../theme/default/colors';
import { mapSentimentToColor } from '../../../../utils';
import { AgentDefaultProps, ArrowIndicatorProps, LeaderboardProps } from '../../types';
import { ElementType } from '../../types/agentJsonData';
import { mapLeaderboardItemSentimentToSentiment } from '../../utils';
import { AgentContentRenderer } from '../AgentContentRenderer/AgentContentRenderer';
import AgentTextElement from '../AgentScreenItems/AgentTextElement';

const ArrowIndicatorIcon: FC<ArrowIndicatorProps> = memo(({ sentiment }) => {
  switch (sentiment) {
    case T.Sentiment.Positive:
      return <TriangleUp color="success" sx={{ fontSize: 16 }} />;
    case T.Sentiment.Negative:
      return <TriangleDown color="error" sx={{ fontSize: 16 }} />;
    default:
      return null;
  }
});

const AgentDefault: React.FC<AgentDefaultProps> = ({ screen }) => {
  const renderLeaderboard = useCallback((element: LeaderboardProps) => {
    const { id, content, variant } = element;
    return (
      <Stack data-testId={`leaderboard-${variant}`} id={id}>
        <Stack flexDirection="column">
          {content.map((item, index) => {
            const itemSentiment = mapLeaderboardItemSentimentToSentiment(
              item.formatted_value.sentiment,
            );
            return (
              <Stack
                key={index}
                sx={{
                  '&:not(:last-child)': {
                    borderBottom: '2px solid rgba(255, 255, 255, 0.16)',
                  },
                  paddingBottom: 2,
                  paddingTop: 2,
                  paddingLeft: 0,
                }}
              >
                <Stack
                  flexDirection="row"
                  width="100%"
                  gap="10px"
                  key={index}
                  alignItems="flex-start"
                  justifyContent="space-between"
                  className={`leaderboard-item ${item.sentiment}`}
                >
                  <Typography
                    variant="h8"
                    fontWeight={700}
                    sx={{ minWidth: 'fit-content' }}
                    color="grey"
                  >
                    #{item.rank}
                  </Typography>
                  <Stack flexDirection="column" justifyContent="space-between" width="100%">
                    <Typography variant="h8" fontWeight={700} sx={{ flexGrow: 1 }}>
                      {item.title}
                    </Typography>
                    <Typography variant="h9" color="grey" sx={{ flexGrow: 1 }}>
                      Previous rank #{item.previous_rank}
                    </Typography>
                    <Stack
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      marginTop={1}
                      gap="4px"
                    >
                      <Typography
                        variant="h9"
                        fontWeight={510}
                        color={mapSentimentToColor(itemSentiment)}
                      >
                        {item.formatted_value.text}
                      </Typography>
                      <ArrowIndicatorIcon sentiment={itemSentiment} />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    );
  }, []);

  return (
    <AgentContentRenderer content={screen.content}>
      {(elements) =>
        elements.map((element, elementKey) => (
          <Box key={elementKey}>
            {element.type === ElementType.Text && <AgentTextElement element={element} />}

            {element.type === ElementType.SubtitleText && (
              <Stack>
                <Typography
                  sx={{
                    fontWeight: 510,
                    fontSize: 16,
                    lineHeight: '19px',
                    color: colors.neutrals.grey4,
                  }}
                  textAlign={element?.alignment}
                >
                  {element?.content}
                </Typography>
              </Stack>
            )}

            {element.type === ElementType.LeaderBoard && renderLeaderboard(element)}
          </Box>
        ))
      }
    </AgentContentRenderer>
  );
};

export default AgentDefault;
