import { useCallback, useLayoutEffect } from 'react';

function cleanDocumentElement() {
  const style = document.documentElement.style;
  style.removeProperty('--vh-scale-lg');
  style.removeProperty('--vh-scale-xl');
  style.removeProperty('--vh-scale-xxl');
}

interface Props {
  minScale?: number;
  maxScale?: number;
}

export function useWindowResize({ minScale = 0.8, maxScale = 1 }: Props = {}) {
  const handleWindowResize = useCallback(() => {
    const style = document.documentElement.style;
    style.setProperty(
      '--vh-scale-lg',
      `${Math.min(Math.max(window.innerHeight / 834, minScale), maxScale)}`,
    );
    style.setProperty(
      '--vh-scale-xl',
      `${Math.min(Math.max(window.innerHeight / 900, minScale), maxScale)}`,
    );
    style.setProperty(
      '--vh-scale-xxl',
      `${Math.min(Math.max(window.innerHeight / 1092, minScale), maxScale)}`,
    );
  }, [minScale, maxScale]);

  useLayoutEffect(() => {
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      cleanDocumentElement();
    };
  }, [handleWindowResize]);
}
