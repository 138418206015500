import { SpacingTokenKey } from '@aily/ui-theme';
import { Stack as MuiStack, StackProps as MuiStackProps } from '@mui/material';
import React from 'react';

import { useTokenValue } from '../../hooks';

export interface StackProps extends MuiStackProps {
  /**
   * Defines the space between immediate children.
   * Only design token values are allowed.
   * @default 0
   */
  spacing?: SpacingTokenKey;
}

const Stack: React.FC<StackProps> = ({ spacing = '0', ...rest }) => {
  const spacingValue = useTokenValue('spacing', spacing);
  return <MuiStack useFlexGap spacing={spacingValue} {...rest} />;
};

export default Stack;
