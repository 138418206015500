import * as T from '@aily/graphql-sdk/schema';
import { Heading, HStack } from '@aily-labs/ui';
import { Box } from '@mui/material';
import React, { useCallback } from 'react';

import { ExtendedTextElement } from '../../types';
import { IconMap } from '../../utils/IconMap';

type HeadingType =
  | 'button'
  | 'H1'
  | 'H2'
  | 'H3'
  | 'H3Bold'
  | 'H4'
  | 'H5'
  | 'H6'
  | 'H7'
  | 'H8'
  | 'H9'
  | 'impact'
  | undefined;

const mapHeadingType = (
  text_type: string,
  mapping: { [key: string]: HeadingType },
): HeadingType => {
  return mapping[text_type] || (text_type as HeadingType);
};

const headingMapping: { [key: string]: HeadingType } = {
  H4: 'H7',
  H8: 'H9',
};

interface AgentTextElementProps {
  element: ExtendedTextElement;
  style?: React.CSSProperties;
}

const AgentTextElement: React.FC<AgentTextElementProps> = ({ element, style }) => {
  const handleTextPress = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);
  const { content, text_type } = element;
  const headingType = mapHeadingType(text_type, headingMapping);
  if (!content || (typeof content === 'string' && content.trim() === '')) {
    return null;
  }
  return (
    <HStack sx={{ paddingBottom: element?.display_icon ? 10 : 0, alignItems: 'center', ...style }}>
      <Box alignSelf={'center'}>
        {/* reason why this can't be properly typed now is that we're missing the type from the AI team that will include all options 
      @ts-expect-error */}
        {element?.display_icon && IconMap[element?.sentiment?.toUpperCase()]}
      </Box>
      <Heading
        maxWidth={300}
        data-testid="agent-text-element"
        textAlign={element.alignment}
        adjustsFontSizeToFit
        minimumFontScale={0.1}
        type={headingType}
        color={mapSentimentToDSColor(
          // @ts-expect-error reason why this can't be properly typed now is that we're missing the type from the AI team that will include all options
          element?.display_icon ? T.Sentiment.Neutral : element?.sentiment.toUpperCase(),
        )}
        {...(element.url && { onPress: () => handleTextPress(element.url) })}
      >
        {content as string}
      </Heading>
    </HStack>
  );
};

const mapSentimentToDSColor = (
  sentiment: T.Sentiment.Positive | T.Sentiment.Neutral | T.Sentiment.Negative,
) => {
  return { ...sentimentColorMap?.[sentiment] }.color;
};

const sentimentColorMap = {
  [T.Sentiment.Positive]: { color: '$colorSentimentPositive' },
  [T.Sentiment.Neutral]: { color: '$textColorRegular' },
  [T.Sentiment.Negative]: { color: '$colorSentimentNegative' },
};

export default AgentTextElement;
