import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const DataAlert: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      d="M2 13.6195V12.2861H10V13.6195H2ZM10.6667 8.9528C9.74444 8.9528 8.95833 8.6278 8.30833 7.9778C7.65833 7.3278 7.33333 6.54169 7.33333 5.61947C7.33333 4.69724 7.65833 3.91113 8.30833 3.26113C8.95833 2.61113 9.74444 2.28613 10.6667 2.28613C11.5889 2.28613 12.375 2.61113 13.025 3.26113C13.675 3.91113 14 4.69724 14 5.61947C14 6.54169 13.675 7.3278 13.025 7.9778C12.375 8.6278 11.5889 8.9528 10.6667 8.9528ZM2 8.28613V6.9528H6.2C6.27778 7.19724 6.36667 7.43058 6.46667 7.6528C6.56667 7.87502 6.68889 8.08613 6.83333 8.28613H2ZM2 10.9528V9.61947H8.26667C8.52222 9.77502 8.79444 9.90558 9.08333 10.0111C9.37222 10.1167 9.67778 10.1917 10 10.2361V10.9528H2ZM10.3333 7.61947H11V4.9528H10.3333V7.61947ZM10.6667 4.28613C10.7556 4.28613 10.8333 4.2528 10.9 4.18613C10.9667 4.11947 11 4.04169 11 3.9528C11 3.86391 10.9667 3.78613 10.9 3.71947C10.8333 3.6528 10.7556 3.61947 10.6667 3.61947C10.5778 3.61947 10.5 3.6528 10.4333 3.71947C10.3667 3.78613 10.3333 3.86391 10.3333 3.9528C10.3333 4.04169 10.3667 4.11947 10.4333 4.18613C10.5 4.2528 10.5778 4.28613 10.6667 4.28613Z"
      fill="white"
    />
  </SvgIcon>
);
