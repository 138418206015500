import { getCurrentUser } from '@aily/auth-service';
import { initMonitoringService } from '@aily/monitoring-service';

import envConfig from '@/config/env';

const user = getCurrentUser();

initMonitoringService({
  dsn: envConfig.SENTRY_DSN,
  environment: envConfig.SENTRY_ENVIRONMENT,
  release: envConfig.SENTRY_RELEASE,
  tenant: envConfig.TENANT,
  userEmail: user?.profile.email,
  userId: user?.profile?.sub,
});
