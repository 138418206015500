import * as T from '@aily/graphql-sdk/schema';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import React, { useCallback, useRef } from 'react';

import { NavLink, OptimallyWideMultilineText } from '../../components';

export interface TableCellDrillDownProps {
  drillDown: T.DrillDownResult;
  onClick?: (drillDown: T.DrillDownResult) => void;
  cellTextSxProps?: SxProps<Theme>;
  ellipsis?: boolean;
}

export const TableCellDrillDown: React.FC<TableCellDrillDownProps> = ({
  drillDown,
  onClick,
  cellTextSxProps,
  ellipsis,
}) => {
  const { value, subLabels, drillDownIds, drillUpIds } = drillDown;
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    onClick?.(drillDown);
  }, [onClick, drillDown]);

  const renderContent = () => {
    if (ellipsis) {
      return (
        <Stack overflow="hidden">
          <Typography variant="inherit" color="text.primary" component="span">
            {value}
          </Typography>
          {subLabels?.length && (
            <Typography variant="small" color="text.secondary" component="span">
              {subLabels[0]}
            </Typography>
          )}
        </Stack>
      );
    }

    return (
      <Stack>
        <OptimallyWideMultilineText containerRef={containerRef}>
          <Typography variant="inherit" color="text.primary" component="span">
            {value}
          </Typography>
        </OptimallyWideMultilineText>
        {subLabels?.length && (
          <OptimallyWideMultilineText containerRef={containerRef}>
            <Typography variant="small" color="text.secondary" component="span">
              {subLabels[0]}
            </Typography>
          </OptimallyWideMultilineText>
        )}
      </Stack>
    );
  };

  if (drillDownIds) {
    return (
      <div ref={containerRef}>
        <NavLink forward onClick={handleClick} data-testid="DrillDownCell">
          {renderContent()}
        </NavLink>
      </div>
    );
  }

  if (drillUpIds) {
    return (
      <NavLink back onClick={handleClick} data-testid="DrillUpCell">
        {subLabels?.length ? (
          <Stack>
            <Typography variant="inherit" component="span">
              {value}
            </Typography>
            <Typography variant="small" component="span" color="text.secondary">
              {subLabels[0]}
            </Typography>
          </Stack>
        ) : (
          <Typography variant="inherit" component="span">
            {value}
          </Typography>
        )}
      </NavLink>
    );
  }

  return (
    <NavLink data-testid="DrillDownCell">
      {subLabels?.length ? (
        <Stack>
          <Typography variant="inherit" component="span">
            {value}
          </Typography>
          <Typography variant="small" component="span" color="text.secondary">
            {subLabels[0]}
          </Typography>
        </Stack>
      ) : (
        <Typography variant="inherit" component="span" sx={cellTextSxProps}>
          {value}
        </Typography>
      )}
    </NavLink>
  );
};
