import {
  Center,
  FilterArrowLeftIcon,
  FilterArrowRightIcon,
  SoundOffIcon,
  SoundOnIcon,
} from '@aily-labs/ui';
import { Box, styled } from '@mui/material';

import { colors } from '../../../theme/default/colors';

export const PausePlayButton = styled(Box)(({ progress = 0 }: { progress: number }) => ({
  backgroundColor: '#1A1A1A',
  cursor: 'pointer',
  width: 70,
  height: 70,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',

  '&:before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: '50%',
    padding: 5,
    background: `conic-gradient(
      from 0deg,
      #31D4B6 0%,
      #48FF9B ${progress}%,
      transparent ${progress}%,
      transparent 100%
    )`,
    mask: 'radial-gradient(farthest-side, transparent calc(100% - 5px), #fff 0)',
    WebkitMask: 'radial-gradient(farthest-side, transparent calc(100% - 5px), #fff 0)',
    transform: 'scale(0.95)',
    transition: 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  },

  '&:hover:before': {
    transform: 'scale(1)',
  },
}));

const NavigationButton = styled(Box)(() => ({
  height: 36,
  width: 36,
  padding: 8,
  borderRadius: '100%',
  backgroundColor: '#1A1A1A',
  cursor: 'pointer',
}));

interface AudioControlsProps {
  onToggleAudioState: () => void;
  onNext: () => void;
  onBack: () => void;
  isAudioPlaying: boolean;
  audioProgressPercentage: number;
  isOpeningScreen?: boolean;
}

export const BottomControls: React.FC<AudioControlsProps> = ({
  onToggleAudioState,
  onNext,
  onBack,
  isAudioPlaying,
  audioProgressPercentage,
}) => (
  <Center
    flexDirection="row"
    gap={20}
    marginLeft={120}
    alignSelf="center"
    position="absolute"
    bottom="10%"
  >
    <NavigationButton onClick={onBack}>
      <FilterArrowLeftIcon color={colors.neutrals.white} />
    </NavigationButton>
    <PausePlayButton
      onClick={onToggleAudioState}
      justifyContent="center"
      alignItems="center"
      progress={audioProgressPercentage}
    >
      {isAudioPlaying ? (
        <SoundOnIcon width={20} height={20} />
      ) : (
        <SoundOffIcon width={20} height={20} />
      )}
    </PausePlayButton>
    <NavigationButton onClick={onNext}>
      <FilterArrowRightIcon color={colors.neutrals.white} />
    </NavigationButton>
  </Center>
);
