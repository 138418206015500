import { IconButton, IconButtonProps, SvgIcon, SvgIconProps, Theme, useTheme } from '@mui/material';
import React from 'react';

import { useSlideshow } from './SlideshowContext';
import { SlideshowAction, SlideshowState } from './slideshowState';

const NextIcon = (props: SvgIconProps) => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <path
      d="M0,20C0,8.9554 8.9554,0 20,0C31.04464,0 40,8.9554 40,20C40,31.04464 31.04464,40 20,40C8.9554,40 0,31.04464 0,20Z"
      fill="#1A1A1A"
    />
    <path
      d="M15.2227,27.9477V25.854C15.2227,25.3986 15.4414,24.9656 15.8119,24.6977L22.303,20.0013L15.8119,15.3049C15.4414,15.037 15.2227,14.6084 15.2227,14.1486V12.0553C15.2227,11.7651 15.553,11.5955 15.7896,11.7651L26.7718,19.7115C26.9682,19.8544 26.9682,20.1441 26.7718,20.287L15.7896,28.2334C15.553,28.4075 15.2227,28.2379 15.2227,27.9477Z"
      fill="#A6A6A6"
    />
  </SvgIcon>
);

const getButtonStyles = (profile: string, enabled: boolean, theme: Theme) => {
  if (profile === 'AGENT') {
    return {
      opacity: !enabled ? 0.5 : 1,
      bottom: 40,
      top: 'auto',
      right: 'calc(50% - 78px)',
    };
  }
  return {
    opacity: 1,
    bottom: 'auto',
    top: '50%',
    right: theme.spacing(4),
  };
};

const getButtonProps = (
  profile: string,
  state: SlideshowState,
  dispatch: React.Dispatch<SlideshowAction>,
) => {
  if (profile === 'AGENT') {
    return {
      enabled: state.hasNextSlide,
      disabled: false,
      handleClick: () => {
        if (state.hasNextSlide) {
          dispatch({ type: 'NEXT_SLIDE' });
        }
      },
    };
  }
  return {
    enabled: true,
    disabled: !state.hasNextSlide,
    handleClick: () => {
      dispatch({ type: 'NEXT_SLIDE' });
    },
  };
};

export const SlideshowNextButton: React.FC<IconButtonProps> = (props) => {
  const { state, dispatch } = useSlideshow();
  const slideShowProfile = state.slideShowProfile;
  const theme = useTheme();
  const { enabled, disabled, handleClick } = getButtonProps(slideShowProfile, state, dispatch);

  return (
    <IconButton
      onClick={handleClick}
      disabled={disabled}
      data-testid="slideshow__next-button"
      {...props}
      sx={{
        ...getButtonStyles(slideShowProfile, enabled, theme),
        position: 'absolute',
        zIndex: 1,
        transform: 'translateY(-50%)',
        transition: 'opacity 0.3s',
        '&.Mui-disabled': {
          opacity: 0,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 40,
        },
      }}
    >
      <NextIcon />
    </IconButton>
  );
};
