import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material/styles';
import React from 'react';

export interface RecommenderCardBackgroundProps {
  color: ColorTokenKey;
  opacity?: number;
}

const RecommenderCardBackgroundRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'opacity',
})<RecommenderCardBackgroundProps>(({ theme, color, opacity }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: theme.tokens.color[color],
  opacity,
}));

const RecommenderCardBackground: React.FC<RecommenderCardBackgroundProps> = ({
  color,
  opacity,
}) => <RecommenderCardBackgroundRoot color={color} opacity={opacity} />;

export default RecommenderCardBackground;
