import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Tenant: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <g clip-path="url(#clip0_49093_20074)">
      <path
        d="M1.33333 14V3.16667L4.66667 0.666667L8 3.16667V4.66667H14.6667V14H1.33333ZM2.66667 12.6667H4V11.3333H2.66667V12.6667ZM2.66667 10H4V8.66667H2.66667V10ZM2.66667 7.33333H4V6H2.66667V7.33333ZM2.66667 4.66667H4V3.33333H2.66667V4.66667ZM5.33333 4.66667H6.66667V3.33333H5.33333V4.66667ZM5.33333 12.6667H13.3333V6H5.33333V12.6667ZM9.33333 8.66667V7.33333H12V8.66667H9.33333ZM9.33333 11.3333V10H12V11.3333H9.33333ZM6.66667 8.66667V7.33333H8V8.66667H6.66667ZM6.66667 11.3333V10H8V11.3333H6.66667Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_49093_20074">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
