import { buttonClasses } from '@mui/base';
import { styled } from '@mui/material/styles';
import React from 'react';

import { ButtonBase, ButtonBaseProps } from '../../buttons';

export interface CardActionAreaProps extends ButtonBaseProps {
  disableFocusHighlight?: boolean;
}

const CardActionAreaRoot = styled(ButtonBase)({
  display: 'block',
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
  textAlign: 'inherit',
});

const CardActionAreaFocusHighlight = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'hidden',
  opacity: 0,
  borderRadius: 'inherit',
  backgroundColor: 'currentColor',
  pointerEvents: 'none',
  transition: 'opacity 0.15s',
  willChange: 'opacity',

  // Button hover and focus
  [`*:hover > &, .${buttonClasses.focusVisible} > &`]: {
    opacity: 0.05,
  },
});

/**
 * The `CardActionArea` component provides an accessible and interactive area for cards.
 */
const CardActionArea: React.FC<CardActionAreaProps> = ({
  children,
  disableFocusHighlight,
  ...rest
}) => (
  <CardActionAreaRoot disableHoverStyle {...rest}>
    {children}
    {!disableFocusHighlight && <CardActionAreaFocusHighlight />}
  </CardActionAreaRoot>
);

export default CardActionArea;
