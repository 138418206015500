import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Star: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="33" viewBox="0 0 32 33" {...props}>
    <path
      d="M13.7615 4.38109C14.6772 2.448 17.3229 2.44801 18.2385 4.3811L21.0955 10.4125C21.1683 10.566 21.3088 10.6725 21.4715 10.6971L27.8599 11.6643C29.9074 11.9742 30.725 14.5958 29.2434 16.1006L24.6207 20.7954C24.503 20.9149 24.4493 21.087 24.4771 21.2558L25.5684 27.885C25.9181 30.0096 23.7777 31.6299 21.9463 30.6267L16.2323 27.4969C16.0869 27.4172 15.9131 27.4172 15.7677 27.4969L10.0537 30.6267C8.22229 31.6299 6.08187 30.0096 6.43163 27.885L7.52291 21.2558C7.55069 21.087 7.49699 20.9149 7.37932 20.7954L2.75659 16.1006C1.27498 14.5958 2.09255 11.9742 4.14008 11.6643L10.5285 10.6971C10.6912 10.6725 10.8317 10.566 10.9045 10.4125L13.7615 4.38109ZM16.4477 5.30194C16.2646 4.91532 15.7354 4.91533 15.5523 5.30194L12.6953 11.3334C12.3317 12.101 11.6288 12.6331 10.8157 12.7561L4.42725 13.7233C4.01774 13.7853 3.85423 14.3097 4.15055 14.6106L8.77329 19.3054C9.36164 19.9029 9.63011 20.7638 9.49122 21.6075L8.39994 28.2367C8.32999 28.6616 8.75807 28.9857 9.12434 28.785L14.8384 25.6552C15.5656 25.2568 16.4344 25.2568 17.1616 25.6552L22.8757 28.785C23.2419 28.9857 23.67 28.6616 23.6001 28.2367L22.5088 21.6075C22.3699 20.7638 22.6384 19.9029 23.2267 19.3054L27.8494 14.6106C28.1458 14.3097 27.9823 13.7853 27.5727 13.7233L21.1843 12.7561C20.3712 12.6331 19.6683 12.101 19.3047 11.3334L16.4477 5.30194Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </SvgIcon>
);
