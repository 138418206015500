import { SpacingTokenValue } from '../../../types';

// Define token object with strict type checking
const spacing = {
  '0': '0px',
  '4': '4px',
  '8': '8px',
  '12': '12px',
  '16': '16px',
  '20': '20px',
  '24': '24px',
  '32': '32px',
  '40': '40px',
  '64': '64px',
  xxsmall: '4px',
  xsmall: '8px',
  small: '12px',
  medium: '16px',
  large: '20px',
  xlarge: '24px',
  xxlarge: '32px',
  xxxlarge: '40px',
  none: '0px',
} as const satisfies Record<string, SpacingTokenValue>;

export default spacing;
