import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type { UserSettingFieldsFragment } from '../../modules/rnd/__generated__/fragments';
import { UserSettingFieldsFragmentDoc } from '../../modules/rnd/__generated__/fragments';
import type * as T from '../../schema/__generated__/types';
import type { PriorityFieldsFragment } from './fragments';
import { PriorityFieldsFragmentDoc } from './fragments';
const defaultOptions = {} as const;
export type ProcessInterceptMutationVariables = T.Exact<{
  input: T.ProcessInterceptInput;
}>;

export type ProcessInterceptMutation = { __typename?: 'Mutation'; processIntercept: boolean };

export type FollowPriorityMutationVariables = T.Exact<{
  input: T.PriorityInput;
}>;

export type FollowPriorityMutation = {
  __typename?: 'Mutation';
  followPriority: { __typename?: 'PriorityResult' } & PriorityFieldsFragment;
};

export type MarkStoryAsReadMutationVariables = T.Exact<{
  input: T.MarkStoryAsReadInput;
}>;

export type MarkStoryAsReadMutation = { __typename?: 'Mutation'; markStoryAsRead: boolean };

export type ReorderPriorityMutationVariables = T.Exact<{
  input: T.PriorityInput;
}>;

export type ReorderPriorityMutation = {
  __typename?: 'Mutation';
  reorderPriority: { __typename?: 'PriorityResult' } & PriorityFieldsFragment;
};

export type SaveUserSettingsMutationVariables = T.Exact<{
  input: T.SaveUserSettingsInput;
}>;

export type SaveUserSettingsMutation = {
  __typename?: 'Mutation';
  saveUserSettings: Array<{ __typename?: 'UserSetting' } & UserSettingFieldsFragment>;
};

export type UnfollowPriorityMutationVariables = T.Exact<{
  input: T.PriorityInput;
}>;

export type UnfollowPriorityMutation = {
  __typename?: 'Mutation';
  unfollowPriority: { __typename?: 'PriorityResult' } & PriorityFieldsFragment;
};

export type AddToBrainStoryMutationVariables = T.Exact<{
  input: T.AddToBrainStoryInput;
}>;

export type AddToBrainStoryMutation = { __typename?: 'Mutation'; addStoryToBrain: boolean };

export type AddToBrainRealMutationVariables = T.Exact<{
  input: T.AddToBrainRealInput;
}>;

export type AddToBrainRealMutation = { __typename?: 'Mutation'; addRealToBrain: boolean };

export const ProcessInterceptDocument = gql`
  mutation ProcessIntercept($input: ProcessInterceptInput!) {
    processIntercept(input: $input)
  }
`;
export type ProcessInterceptMutationFn = Apollo.MutationFunction<
  ProcessInterceptMutation,
  ProcessInterceptMutationVariables
>;

/**
 * __useProcessInterceptMutation__
 *
 * To run a mutation, you first call `useProcessInterceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessInterceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processInterceptMutation, { data, loading, error }] = useProcessInterceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessInterceptMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ProcessInterceptMutation,
    ProcessInterceptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ProcessInterceptMutation, ProcessInterceptMutationVariables>(
    ProcessInterceptDocument,
    options,
  );
}
export type ProcessInterceptMutationHookResult = ReturnType<typeof useProcessInterceptMutation>;
export type ProcessInterceptMutationResult = Apollo.MutationResult<ProcessInterceptMutation>;
export type ProcessInterceptMutationOptions = Apollo.BaseMutationOptions<
  ProcessInterceptMutation,
  ProcessInterceptMutationVariables
>;
export const FollowPriorityDocument = gql`
  mutation FollowPriority($input: PriorityInput!) {
    followPriority(input: $input) {
      ...PriorityFields
    }
  }
  ${PriorityFieldsFragmentDoc}
`;
export type FollowPriorityMutationFn = Apollo.MutationFunction<
  FollowPriorityMutation,
  FollowPriorityMutationVariables
>;

/**
 * __useFollowPriorityMutation__
 *
 * To run a mutation, you first call `useFollowPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followPriorityMutation, { data, loading, error }] = useFollowPriorityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowPriorityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FollowPriorityMutation,
    FollowPriorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<FollowPriorityMutation, FollowPriorityMutationVariables>(
    FollowPriorityDocument,
    options,
  );
}
export type FollowPriorityMutationHookResult = ReturnType<typeof useFollowPriorityMutation>;
export type FollowPriorityMutationResult = Apollo.MutationResult<FollowPriorityMutation>;
export type FollowPriorityMutationOptions = Apollo.BaseMutationOptions<
  FollowPriorityMutation,
  FollowPriorityMutationVariables
>;
export const MarkStoryAsReadDocument = gql`
  mutation MarkStoryAsRead($input: MarkStoryAsReadInput!) {
    markStoryAsRead(input: $input)
  }
`;
export type MarkStoryAsReadMutationFn = Apollo.MutationFunction<
  MarkStoryAsReadMutation,
  MarkStoryAsReadMutationVariables
>;

/**
 * __useMarkStoryAsReadMutation__
 *
 * To run a mutation, you first call `useMarkStoryAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkStoryAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markStoryAsReadMutation, { data, loading, error }] = useMarkStoryAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkStoryAsReadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkStoryAsReadMutation,
    MarkStoryAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<MarkStoryAsReadMutation, MarkStoryAsReadMutationVariables>(
    MarkStoryAsReadDocument,
    options,
  );
}
export type MarkStoryAsReadMutationHookResult = ReturnType<typeof useMarkStoryAsReadMutation>;
export type MarkStoryAsReadMutationResult = Apollo.MutationResult<MarkStoryAsReadMutation>;
export type MarkStoryAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkStoryAsReadMutation,
  MarkStoryAsReadMutationVariables
>;
export const ReorderPriorityDocument = gql`
  mutation ReorderPriority($input: PriorityInput!) {
    reorderPriority(input: $input) {
      ...PriorityFields
    }
  }
  ${PriorityFieldsFragmentDoc}
`;
export type ReorderPriorityMutationFn = Apollo.MutationFunction<
  ReorderPriorityMutation,
  ReorderPriorityMutationVariables
>;

/**
 * __useReorderPriorityMutation__
 *
 * To run a mutation, you first call `useReorderPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderPriorityMutation, { data, loading, error }] = useReorderPriorityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderPriorityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReorderPriorityMutation,
    ReorderPriorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ReorderPriorityMutation, ReorderPriorityMutationVariables>(
    ReorderPriorityDocument,
    options,
  );
}
export type ReorderPriorityMutationHookResult = ReturnType<typeof useReorderPriorityMutation>;
export type ReorderPriorityMutationResult = Apollo.MutationResult<ReorderPriorityMutation>;
export type ReorderPriorityMutationOptions = Apollo.BaseMutationOptions<
  ReorderPriorityMutation,
  ReorderPriorityMutationVariables
>;
export const SaveUserSettingsDocument = gql`
  mutation SaveUserSettings($input: SaveUserSettingsInput!) {
    saveUserSettings(input: $input) {
      ...UserSettingFields
    }
  }
  ${UserSettingFieldsFragmentDoc}
`;
export type SaveUserSettingsMutationFn = Apollo.MutationFunction<
  SaveUserSettingsMutation,
  SaveUserSettingsMutationVariables
>;

/**
 * __useSaveUserSettingsMutation__
 *
 * To run a mutation, you first call `useSaveUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserSettingsMutation, { data, loading, error }] = useSaveUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserSettingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveUserSettingsMutation,
    SaveUserSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveUserSettingsMutation, SaveUserSettingsMutationVariables>(
    SaveUserSettingsDocument,
    options,
  );
}
export type SaveUserSettingsMutationHookResult = ReturnType<typeof useSaveUserSettingsMutation>;
export type SaveUserSettingsMutationResult = Apollo.MutationResult<SaveUserSettingsMutation>;
export type SaveUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  SaveUserSettingsMutation,
  SaveUserSettingsMutationVariables
>;
export const UnfollowPriorityDocument = gql`
  mutation UnfollowPriority($input: PriorityInput!) {
    unfollowPriority(input: $input) {
      ...PriorityFields
    }
  }
  ${PriorityFieldsFragmentDoc}
`;
export type UnfollowPriorityMutationFn = Apollo.MutationFunction<
  UnfollowPriorityMutation,
  UnfollowPriorityMutationVariables
>;

/**
 * __useUnfollowPriorityMutation__
 *
 * To run a mutation, you first call `useUnfollowPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowPriorityMutation, { data, loading, error }] = useUnfollowPriorityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowPriorityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnfollowPriorityMutation,
    UnfollowPriorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UnfollowPriorityMutation, UnfollowPriorityMutationVariables>(
    UnfollowPriorityDocument,
    options,
  );
}
export type UnfollowPriorityMutationHookResult = ReturnType<typeof useUnfollowPriorityMutation>;
export type UnfollowPriorityMutationResult = Apollo.MutationResult<UnfollowPriorityMutation>;
export type UnfollowPriorityMutationOptions = Apollo.BaseMutationOptions<
  UnfollowPriorityMutation,
  UnfollowPriorityMutationVariables
>;
export const AddToBrainStoryDocument = gql`
  mutation AddToBrainStory($input: AddToBrainStoryInput!) {
    addStoryToBrain(input: $input)
  }
`;
export type AddToBrainStoryMutationFn = Apollo.MutationFunction<
  AddToBrainStoryMutation,
  AddToBrainStoryMutationVariables
>;

/**
 * __useAddToBrainStoryMutation__
 *
 * To run a mutation, you first call `useAddToBrainStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToBrainStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToBrainStoryMutation, { data, loading, error }] = useAddToBrainStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToBrainStoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddToBrainStoryMutation,
    AddToBrainStoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddToBrainStoryMutation, AddToBrainStoryMutationVariables>(
    AddToBrainStoryDocument,
    options,
  );
}
export type AddToBrainStoryMutationHookResult = ReturnType<typeof useAddToBrainStoryMutation>;
export type AddToBrainStoryMutationResult = Apollo.MutationResult<AddToBrainStoryMutation>;
export type AddToBrainStoryMutationOptions = Apollo.BaseMutationOptions<
  AddToBrainStoryMutation,
  AddToBrainStoryMutationVariables
>;
export const AddToBrainRealDocument = gql`
  mutation AddToBrainReal($input: AddToBrainRealInput!) {
    addRealToBrain(input: $input)
  }
`;
export type AddToBrainRealMutationFn = Apollo.MutationFunction<
  AddToBrainRealMutation,
  AddToBrainRealMutationVariables
>;

/**
 * __useAddToBrainRealMutation__
 *
 * To run a mutation, you first call `useAddToBrainRealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToBrainRealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToBrainRealMutation, { data, loading, error }] = useAddToBrainRealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToBrainRealMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddToBrainRealMutation,
    AddToBrainRealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddToBrainRealMutation, AddToBrainRealMutationVariables>(
    AddToBrainRealDocument,
    options,
  );
}
export type AddToBrainRealMutationHookResult = ReturnType<typeof useAddToBrainRealMutation>;
export type AddToBrainRealMutationResult = Apollo.MutationResult<AddToBrainRealMutation>;
export type AddToBrainRealMutationOptions = Apollo.BaseMutationOptions<
  AddToBrainRealMutation,
  AddToBrainRealMutationVariables
>;
