// decision levers due to their complexity and abstraction for now remain untyped
// once we know for sure the scope this component will have and it stops changing regularly, it will be efficient and a must to type it
import { useAnalyticsTrackEvent } from '@aily/analytics-service';
import { StoryDecision } from '@aily/graphql-sdk/schema';
import { formatTestIdString, useProFiltersContext } from '@aily/saas-core';
import { alpha, Box, MenuItem, Stack, styled } from '@mui/material';
import { isEmpty, isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DiscreteSlider } from '../../../../components/DiscreteSlider';
import { SegmentControl } from '../../../../components/SegmentControl';
import {
  AgentJsonData,
  Content,
  Element,
  ElementType,
  Screen,
  ScreenDictScreen,
  SliderValue,
  SliderValueType,
  SuperSliderValueType,
} from '../../types/agentJsonData';
import AgentInfo from './AgentInfo';
import { AilyRecommends } from './AilyRecommends';

type ComponentValue =
  | 'share'
  | 'add_to_brain'
  | 'lets_do_it'
  | 'mute'
  | 'unmute'
  | 'open_scenario'
  | 'dive_in'
  | 'change_slider'
  | 'change_switch_tab'
  | 'Asset'
  | 'Product'
  | 'Enterprise';

const extractModule = (content: Content[]) => {
  return content.flatMap((item) =>
    item.elements.flatMap((elementGroup) =>
      elementGroup.flatMap((element: Element[]) => element.map((el) => el)),
    ),
  );
};

const testSliderPositions = (
  sliderCount: number,
  positionCount: number,
  fixedIndex: number,
  fixedValue: number,
  testFunction: (combinedValue: string) => string | undefined,
) => {
  // @ts-expect-error due to time constraints, this remains untyped, read comment on top
  const generate = (currentPosition, index) => {
    if (index === sliderCount) {
      if (testFunction([...currentPosition].join(','))) {
        return [...currentPosition];
      }
      return null;
    }

    if (index === fixedIndex) {
      currentPosition[index] = fixedValue;
      return generate(currentPosition, index + 1);
    } else {
      for (let i = 0; i < positionCount; i++) {
        currentPosition[index] = i;
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        const result = generate(currentPosition, index + 1);
        if (result) {
          return result;
        }
      }
    }

    return null;
  };

  return generate(Array(sliderCount).fill(0), 0);
};

const PanelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  width: '325px',
}));

const PanelContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  width: 250,
  height: '100%',
}));

const PanelControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
}));

const SegmentControlPanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1.25),
  borderRadius: 20,
  padding: 20,
  backgroundColor: alpha(theme.palette.grey[100], 0.5),
  marginTop: 10,
}));

type Props = {
  screen: Screen;
  baseAudioURL: string;
  agentJsonData: AgentJsonData;
  decision: StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
  onSetExtractedLeversScreens: (screens: Screen[]) => void;
  switchValue: { value: number };
  onSetSwitchValue: ({ value }: { value: number }) => void;
  sliderValues: SliderValueType;
  onSetSliderValues: (sliderValues: SliderValueType) => void;
  optimizationScope: number;
  onSetOptimizationScope: (value: number) => void;
};

export const DecisionLevers: React.FC<Props> = ({
  screen,
  // this will stay commented until proper typing is implemented for agents and then it can be used again
  // baseAudioURL,
  agentJsonData,
  // decision,
  // onAgentClose,
  // onBackdropClose,
  onSetExtractedLeversScreens,
  switchValue: initialSwitchValue,
  onSetSwitchValue,
  sliderValues: initialSliderValues,
  onSetSliderValues,
  optimizationScope: initialOptimizationScope,
  onSetOptimizationScope,
}) => {
  const { optimisation_levers } = screen;
  const trackEvent = useAnalyticsTrackEvent();
  const proFiltersContext = useProFiltersContext();
  const [optimizationScope, setOptimizationScope] = useState(initialOptimizationScope ?? 0);
  const [switchValue, setSwitchValue] = useState(initialSwitchValue ?? { value: 0 });
  const [sliderValues, setSliderValues] = useState<SliderValueType>(initialSliderValues ?? {});
  // this will stay commented until proper typing is implemented for agents and then it can be used again
  // const [popup, setPopup] = useState<PopupProps & { screens: Screen[]; name: string }>({});
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const currentSwitch = optimisation_levers?.[switchValue.value];
  const currentSwitchContent = currentSwitch?.content ? currentSwitch.content : [];
  const currentModule = extractModule(screen?.content)[0];
  const agentInfo = agentJsonData?.imp_agent ? agentJsonData?.imp_agent : agentJsonData?.opt_agent;

  const setInitialValues = useCallback(
    () =>
      !!currentSwitchContent &&
      currentSwitchContent.map(({ elements }) =>
        elements?.map((row) =>
          row.map((col) =>
            col.forEach((element) => {
              if (element.type === ElementType.Slider) {
                let newSliderValues = {};
                setSliderValues((currentSliderValues) => {
                  if (!isEmpty(sliderValues)) {
                    return sliderValues;
                  }

                  newSliderValues = {
                    ...currentSliderValues,
                    [switchValue.value]: {
                      ...currentSliderValues[switchValue.value],
                      [element.id]: {
                        label: element.name,
                        selectedValue: element.default_value,
                        positions: element.variables.length,
                      },
                    },
                  };
                  return newSliderValues;
                });
                if (!isEmpty(newSliderValues) && isEmpty(initialSliderValues)) {
                  onSetSliderValues(newSliderValues);
                }
              }
            }),
          ),
        ),
      ),
    [optimisation_levers, switchValue, setSliderValues, onSetSliderValues],
  );

  const optimizationScopeArr = useMemo(() => {
    return optimisation_levers?.[optimizationScope];
  }, [optimizationScope, optimisation_levers]);

  const combinedSliderValues = useMemo(() => {
    const activeSwitchSlidersIds = Object.keys(sliderValues?.[switchValue.value] || {});

    return activeSwitchSlidersIds?.reduce(
      (carry, id, index) =>
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        (carry += `${sliderValues?.[switchValue.value]?.[id]?.selectedValue}${index + 1 === activeSwitchSlidersIds.length ? '' : ','}`),
      '',
    );
  }, [sliderValues, switchValue]);

  const currentPopupScreens = useMemo(() => {
    const screenDict =
      optimisation_levers?.[switchValue.value]?.screen_dict?.[combinedSliderValues];

    if (screenDict) {
      return screenDict.screens.reduce(
        (carry, screen) => [
          ...carry,
          {
            ...screen,
            is_recommended: screenDict?.is_recommended,
          },
        ],
        [] as ScreenDictScreen[],
      );
    }
  }, [optimisation_levers, switchValue, combinedSliderValues]);

  const getDecisionImput = (
    nSliderValues: SuperSliderValueType,
  ): { name: string; value: string }[] => {
    const result: { name: string; value: string }[] = [];
    Object.keys(nSliderValues).forEach((switchKey: string) => {
      const switchValues: SliderValueType = nSliderValues[switchKey];
      Object.keys(switchValues).forEach((sliderKey: string) => {
        const slider: SliderValue = switchValues[sliderKey];
        result.push({
          name: slider.name || '',
          value: slider.label || '',
        });
      });
    });
    return result;
  };

  const getRecommendedValues = useCallback(() => {
    const obj = optimisation_levers?.[switchValue.value]?.screen_dict;

    if (!obj) return [];

    const recommendedKeys = Object.keys(obj).filter((key) => obj[key].is_recommended);

    if (recommendedKeys.length === 0) return [];

    const sliders =
      optimizationScopeArr?.content[0].elements.flatMap((row) =>
        row.flatMap((col) => col.filter((element) => element.type === 'slider')),
      ) || [];

    const result = recommendedKeys[0].split(',').map((element, i) => {
      const slider = sliders[i];
      if (!slider) return { name: '', value: '' };
      return {
        name: slider?.name || '',
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        value: slider?.variables[element] || '',
      };
    });

    return result;
  }, [optimisation_levers, switchValue, optimizationScopeArr]);

  const handleSlidingComplete = useCallback(
    (id: string, val: { value: number; label: string; name: string }) => {
      setSliderValues((prevValues) => {
        const newSliderValues = {
          ...prevValues,
          [switchValue.value]: {
            ...prevValues[switchValue.value],
            // @ts-expect-error due to time constraints, this remains untyped, read comment on top
            [id]: { ...prevValues[switchValue.value][id], ...val },
          },
        };
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        const activeSwitchSliders = newSliderValues[switchValue.value];
        const activeSwitchSlidersIds = Object.keys(activeSwitchSliders);

        const newCombinedSliderValues = activeSwitchSlidersIds.reduce(
          (carry, id, index) =>
            (carry += `${activeSwitchSliders[id].selectedValue}${index + 1 === activeSwitchSlidersIds.length ? '' : ','}`),
          '',
        );

        const validSliderValueCombinations = Object.keys(
          optimisation_levers?.[switchValue.value]?.screen_dict || {},
        );

        const checkIfValidValue = (combinedValue: string) =>
          validSliderValueCombinations.find((value) => combinedValue === value);

        const isValidCombination = checkIfValidValue(newCombinedSliderValues);

        if (isValidCombination) {
          handleValidCombination(id, newSliderValues);
          return newSliderValues;
        } else {
          return handleInvalidCombination(
            newSliderValues,
            id,
            activeSwitchSliders,
            activeSwitchSlidersIds,
            checkIfValidValue,
          );
        }
      });
    },
    [optimisation_levers, switchValue, setSliderValues, onSetSliderValues],
  );

  const handleValidCombination = (id: string, newSliderValues: SliderValueType) => {
    trackEvent('agent.edited', {
      name: agentInfo?.name ?? '',
      component: 'agent',
      component_id: id.toString(),
      intent: 'edit',
      item_type: 'slider',
      event_version: '2.1.0',
      screen_id: `${screen.screen_id.toString()}`,
      filter_name: proFiltersContext?.selectedFilters.map((filter) => filter.label),
      // @ts-expect-error due to time constraints, this remains untyped, read comment on top
      decision_input: getDecisionImput(newSliderValues) as { name: string; value: string }[],
      recommended_values: getRecommendedValues(),
    });
    onSetSliderValues(newSliderValues);
  };

  const handleInvalidCombination = (
    newSliderValues: SliderValueType,
    id: string,
    activeSwitchSliders: any,
    activeSwitchSlidersIds: string[],
    checkIfValidValue: (combinedValue: string) => string | undefined,
  ) => {
    const activeSliderValue = activeSwitchSliders[id].selectedValue;
    const activeSliderIndex = activeSwitchSlidersIds.findIndex((sliderId) => sliderId === id);

    const validSliderValuesCombination = testSliderPositions(
      activeSwitchSlidersIds.length,
      activeSwitchSliders[id].positions,
      activeSliderIndex,
      activeSliderValue,
      checkIfValidValue,
    );

    if (validSliderValuesCombination) {
      const sliders = optimizationScopeArr?.content[switchValue.value].elements.flatMap((row) =>
        row.flatMap((col) => col.filter((element) => element.type === 'slider')),
      );
      // @ts-expect-error due to time constraints, this remains untyped, read comment on top
      const result = validSliderValuesCombination.reduce((acc, element, i) => {
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        const slider = sliders[i];
        acc[slider.id] = {
          name: slider.name,
          selectedValue: element,
          positions: slider.variables.length,
          label: slider.variables[element],
        };
        return acc;
      }, {});

      const finalSliderValues = { 0: result };

      trackEvent('agent.edited', {
        name: agentInfo?.name ?? '',
        component: 'agent',
        component_id: id.toString(),
        intent: 'edit',
        item_type: 'slider',
        event_version: '2.1.0',
        screen_id: `${screen.screen_id.toString()}`,
        filter_name: proFiltersContext?.selectedFilters.map((filter) => filter.label),
        decision_input: getDecisionImput(finalSliderValues) as { name: string; value: string }[],
        recommended_values: getRecommendedValues(),
      });
      onSetSliderValues(finalSliderValues);
      return finalSliderValues;
    }

    onSetSliderValues(newSliderValues);
    return newSliderValues;
  };

  const onChangeSwitch = useCallback(
    (value: number) => {
      setOptimizationScope(value);
      onSetOptimizationScope(value);
      setSliderValues({});
      onSetSliderValues({});
      setSwitchValue({ value });
      onSetSwitchValue({ value });
      if (optimisation_levers) {
        trackEvent('agent.clicked', {
          name: agentInfo?.name ?? '',
          component: 'agent',
          component_id: currentModule.id,
          event_version: '2.1.0',
          item_type: 'tab',
          intent: 'click',
          component_value: optimisation_levers[value].name as ComponentValue,
          filter_name: proFiltersContext?.selectedFilters.map((filter) => filter.label),
          screen_id: screen.screen_id.toString(),
        });
      }
    },
    [
      setOptimizationScope,
      onSetOptimizationScope,
      setSliderValues,
      onSetSliderValues,
      setSwitchValue,
      onSetSwitchValue,
    ],
  );

  // this will stay commented until proper typing is implemented for agents and then it can be used again
  // const onOpenScenarioButtonClick = useCallback(() => {
  //   const screenDict =
  //     optimisation_levers?.[switchValue.value]?.screen_dict?.[combinedSliderValues];

  //   if (screenDict) {
  //     const slides = {
  //       ...screenDict,
  //       screens: screenDict.screens.reduce(
  //         (carry, screen) => [
  //           ...carry,
  //           {
  //             ...screen,
  //             is_recommended: screenDict?.is_recommended,
  //           },
  //         ],
  //         [] as ScreenDictScreen[],
  //       ),
  //     };

  //     // @ts-expect-error due to time constraints, this remains untyped, read comment on top
  //     setPopup(slides);
  //     setIsPopupOpen(true);
  //   }
  // }, [setPopup, setIsPopupOpen, optimisation_levers, switchValue, combinedSliderValues]);

  const shouldDisplayAilyRecommends = useCallback(() => {
    const screenDict =
      optimisation_levers?.[switchValue.value]?.screen_dict?.[combinedSliderValues];
    return screenDict?.is_recommended;
  }, [switchValue, combinedSliderValues]);

  useEffect(() => {
    setInitialValues();
  }, [setInitialValues, switchValue]);

  useEffect(() => {
    onSetExtractedLeversScreens(currentPopupScreens ?? []);
  }, [currentPopupScreens]);

  return (
    <>
      <PanelContainer data-testid="decision-levers-content">
        <PanelContent>
          <PanelControls>
            {/*<AgentContentRenderer content={screen.content}>
              {(elements) =>
                elements.map((element, elementKey) => (
                  <>
                    {element.type === ElementType.Text && (
                      <AgentTextElement key={elementKey} element={element} />
                    )}
                  </>
                ))
              }
            </AgentContentRenderer>*/}
            <SegmentControlPanel>
              <SegmentControl
                data-testid={`test-id-segment-control-component`}
                value={optimizationScope}
                onChange={onChangeSwitch}
                sx={{ width: 'calc(100% + 18px)' }}
              >
                {(optimisation_levers ?? []).map(({ name }, index) => (
                  <MenuItem
                    data-testid={formatTestIdString(`test-id-segment-control-item-${name}`)}
                    key={index}
                    value={index}
                  >
                    {name}
                  </MenuItem>
                ))}
              </SegmentControl>
            </SegmentControlPanel>

            {shouldDisplayAilyRecommends() && <AilyRecommends />}
            {optimizationScopeArr?.content?.map(({ elements }) =>
              elements?.map((row) =>
                row.map((col, colIndex) => (
                  <Stack key={colIndex}>
                    {col.map((element, elementIndex) => (
                      <Stack key={elementIndex}>
                        {/* @ts-expect-error due to time constraints, this remains untyped, read comment on top */}
                        {!isNil(sliderValues?.[switchValue.value]?.[element.id]?.selectedValue) &&
                          element.type === ElementType.Slider && (
                            <>
                              <DiscreteSlider
                                testID={formatTestIdString(`test-discrete-slider-${element.name}`)}
                                label={element.name}
                                values={element.variables}
                                isDisabled={element.variables.length <= 1}
                                selectedValue={
                                  // @ts-expect-error due to time constraints, this remains untyped, read comment on top
                                  sliderValues?.[switchValue.value]?.[element.id]?.selectedValue
                                }
                                // @ts-expect-error due to time constraints, this remains untyped, read comment on top
                                onChange={(val) => handleSlidingComplete(element.id, val)}
                                BoxProps={{ sx: { px: 2 } }}
                              />
                            </>
                          )}
                        {element.type === ElementType.Info && (
                          <AgentInfo
                            text={
                              optimisation_levers?.[switchValue.value]?.screen_dict[
                                combinedSliderValues
                              ]?.button_value?.[0] || ''
                            }
                            text2={
                              optimisation_levers?.[switchValue.value]?.screen_dict[
                                combinedSliderValues
                              ]?.button_value?.[1] || ''
                            }
                          />
                        )}

                        {/* this will stay commented until proper typing is implemented for agents and then it can be used again */}
                        {/* {element.type === ElementType.Button && (
                          <Button
                            testID="test-button-open-scenario"
                            type="secondary"
                            title={element.content || ''}
                            width={234}
                            onPress={() => onOpenScenarioButtonClick()}
                          />
                        )} */}

                        {/* this will stay commented until proper typing is implemented for agents and then it can be used again */}
                        {/* {element.type === ElementType.Logos && (
                          <LogosList logoList={element.content} />
                        )} */}
                      </Stack>
                    ))}
                  </Stack>
                )),
              ),
            )}
          </PanelControls>
        </PanelContent>
      </PanelContainer>

      {/* this will stay commented until proper typing is implemented for agents and then it can be used again */}
      {/* {popup && (
        <AgentDecisionLeversPopup
          data-testid={`desicion-levers-popup`}
          title={popup.name}
          screens={popup.screens}
          open={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          baseAudioURL={baseAudioURL}
          agentJsonData={agentJsonData}
          decision={decision}
          textElementStyle={{ paddingBottom: 0 }}
          onAgentClose={onAgentClose}
          onBackdropClose={onBackdropClose}
        />
      )} */}
    </>
  );
};
