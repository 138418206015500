import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const SearchIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path d="M23.4897 22.6327C25.5552 20.5408 26.8304 17.665 26.8304 14.4908C26.8304 8.09491 21.6525 2.91 15.2652 2.91C8.87794 2.91 3.70001 8.09491 3.70001 14.4908C3.70001 20.8867 8.87794 26.0716 15.2652 26.0716C17.6466 26.0716 19.8598 25.3509 21.6993 24.1155L28.3257 30.7508C28.7774 31.2031 29.5096 31.2031 29.9613 30.7508C30.4129 30.2985 30.4129 29.5653 29.9613 29.113L23.4897 22.6327ZM24.5174 14.4908C24.5174 19.6076 20.3751 23.7555 15.2652 23.7555C10.1554 23.7555 6.01306 19.6076 6.01306 14.4908C6.01306 9.3741 10.1554 5.22617 15.2652 5.22617C20.3751 5.22617 24.5174 9.3741 24.5174 14.4908Z" />
  </SvgIcon>
);

export default SearchIcon;
