import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const PlaiIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      d="M4.1729 4C2.97581 4 2 4.96243 2 6.14312V25.8569C2 27.0376 2.97581 28 4.1729 28H27.8271C29.0242 28 30 27.0376 30 25.8569V6.14312C30 4.96243 29.0242 4 27.8271 4H4.1729ZM3.75 6.14312C3.75 5.91569 3.9423 5.72602 4.1729 5.72602H27.8271C28.0577 5.72602 28.25 5.91569 28.25 6.14312V25.8569C28.25 26.0843 28.0577 26.274 27.8271 26.274H4.1729C3.9423 26.274 3.75 26.0843 3.75 25.8569V6.14312Z"
      fill="white"
      fill-rule="evenodd"
      clip-rule="evenodd"
    />
    <path
      d="M13.6205 9.71046C12.2227 8.93842 10.5334 9.93592 10.5334 11.4875V20.5123C10.5334 22.1081 12.2892 23.0007 13.6097 22.2953L13.6157 22.2921L21.6725 17.7743L21.6809 17.7695C23.0384 16.9884 23.0384 15.0434 21.6809 14.2624L13.6278 9.71448L13.6205 9.71046ZM12.2834 11.4875C12.2834 11.2488 12.5381 11.0931 12.7629 11.215L20.8011 15.7545C20.998 15.8697 20.9983 16.1612 20.8019 16.2769L12.7707 20.7805C12.5451 20.8959 12.2834 20.7182 12.2834 20.5123V11.4875Z"
      fill="#48ff9b"
      fill-rule="evenodd"
      clip-rule="evenodd"
    />
  </SvgIcon>
);

export default PlaiIcon;
