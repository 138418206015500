import { Input as BaseInput, inputClasses as baseInputClasses } from '@mui/base/Input';
import { styled } from '@mui/material';
import * as React from 'react';
import { useCallback, useState } from 'react';

import { ButtonBase } from '../../buttons';
import { CloseIcon, IconName, icons } from '../../icons';

export interface TextInputProps {
  value?: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
  clearable?: boolean;
  startIcon?: IconName;
  fullWidth?: boolean;
}

const TextInputRoot = styled('div')<{ fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.tokens.spacing['xsmall'],

  height: 48,
  width: fullWidth ? '100%' : 'auto',
  boxSizing: 'border-box',

  padding: theme.spacing(theme.tokens.spacing['xxsmall'], theme.tokens.spacing['medium']),
  borderRadius: theme.tokens.borderRadius['medium'],
  backgroundColor: theme.tokens.color['background.light'],
  color: theme.tokens.color['text.regular'],

  ...theme.tokens.typography['body.regular'],

  cursor: 'text',

  [`&.${baseInputClasses.disabled}`]: {
    cursor: 'default',
    color: theme.tokens.color['text.subtle'],
  },
}));

const TextInputInput = styled('input')(({ theme }) => ({
  display: 'block',
  minWidth: 0,
  width: '100%',

  margin: 0,
  padding: 0,
  border: 'none',
  outline: 'none',
  background: 'none',

  font: 'inherit',
  letterSpacing: 'inherit',
  color: 'inherit',

  '&::placeholder': {
    color: theme.tokens.color['neutral.400'],
  },
}));

const TextInputAdornment = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.tokens.color['neutral.400'],
}));

const TextInput: React.FC<TextInputProps> = ({
  value,
  onChange,
  defaultValue,
  placeholder,
  clearable,
  startIcon,
  fullWidth,
}) => {
  const [localValue, setLocalValue] = useState(defaultValue);
  const isControlled = value !== undefined;
  const inputValue = isControlled ? value : localValue;
  const StartIconComponent = startIcon ? icons[startIcon] : undefined;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (!isControlled) {
        setLocalValue(newValue);
      }
      onChange?.(newValue);
    },
    [isControlled, onChange],
  );

  const handleClear = useCallback(() => {
    if (!isControlled) {
      setLocalValue('');
    }
    onChange?.('');
  }, [isControlled, onChange]);

  return (
    <BaseInput<typeof TextInputRoot>
      slots={{ root: TextInputRoot, input: TextInputInput }}
      value={inputValue}
      onChange={handleChange}
      placeholder={placeholder}
      fullWidth={fullWidth}
      startAdornment={
        StartIconComponent && (
          <TextInputAdornment>
            <StartIconComponent size="icon.medium" />
          </TextInputAdornment>
        )
      }
      endAdornment={
        clearable &&
        inputValue && (
          <TextInputAdornment>
            <ButtonBase
              onClick={handleClear}
              aria-label="Clear input"
              data-testid="text-input__clear-button"
            >
              <CloseIcon size="icon.medium" />
            </ButtonBase>
          </TextInputAdornment>
        )
      }
      data-testid="text-input"
    />
  );
};

export default TextInput;
