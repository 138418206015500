import { VStack } from '@aily-labs/ui';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import { Content, Element } from '../../types/agentJsonData';

type Props = {
  content: Content[];
  children: (element: Element[]) => ReactNode;
};

export const AgentContentRenderer = ({ content, children }: Props) => {
  return (
    <Stack paddingBottom={2}>
      {content?.map((row, rowIndex) => {
        return (
          <Stack data-testid="agent-content-renderer" key={`row-${rowIndex}`} paddingBottom={2}>
            {row.elements?.map((col, colIndex) => {
              return (
                <Stack key={`col-${rowIndex}-${colIndex}`}>
                  {col.map((element, elementIndex) => {
                    return <VStack key={`element-${elementIndex}`}>{children(element)}</VStack>;
                  })}
                </Stack>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};
