import React, { createContext, ProviderProps, useContext } from 'react';

import { RecommenderCardSize } from './RecommenderCard';

export interface RecommenderCardGroupContextValue {
  size: RecommenderCardSize;
}

const RecommenderCardGroupContext = createContext<RecommenderCardGroupContextValue | undefined>(
  undefined,
);

export const RecommenderCardGroupProvider: React.FC<
  ProviderProps<RecommenderCardGroupContextValue>
> = ({ children, value }) => (
  <RecommenderCardGroupContext.Provider value={value}>
    {children}
  </RecommenderCardGroupContext.Provider>
);

export const useRecommenderCardGroupContext = () => {
  return useContext(RecommenderCardGroupContext);
};
