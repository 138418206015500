import * as T from '@aily/graphql-sdk/schema';
import { ExperimentIcon } from '@aily-labs/ui';
import React from 'react';

import { RhombusSymbol } from '../components/Timeline';
import {
  AiActive,
  CalendarOutlined,
  DataAlert,
  EuroCircleOutlined,
  OpenInNew,
  PeopleOutlined,
  Tenant,
} from '../icons';
import { colors } from '../theme/default/colors';

export const getIconComponent = (icon: T.Icon) => {
  return {
    [T.Icon.Calendar]: CalendarOutlined,
    [T.Icon.Ai]: AiActive,
    [T.Icon.OpenInNew]: OpenInNew,
    [T.Icon.Analyzer]: () =>
      React.createElement(ExperimentIcon, {
        color: colors.neutrals.grey3,
      }),
    [T.Icon.Euro]: EuroCircleOutlined,
    [T.Icon.People]: PeopleOutlined,
    [T.Icon.FileInfo]: Tenant,
    [T.Icon.Building]: DataAlert,
    [T.Icon.Rhombus]: (props: { style?: React.CSSProperties }) =>
      React.createElement(RhombusSymbol, {
        size: 22,
        stroke: 'currentColor',
        fill: colors.secondary.darkBlue,
        ...props,
      }),
  }[icon];
};
