import React from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const TrendArrowDownThickIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      d="M5.98968 22.0482C5.05216 20.6172 4.44115 18.997 4.20723 17.2991C3.90307 15.0912 4.24916 12.8441 5.20464 10.823C6.16012 8.80185 7.68509 7.09116 9.5995 5.89285C11.5139 4.69455 13.7378 4.05867 16.0087 4.0603C18.2795 4.06192 20.5025 4.70099 22.4151 5.90203C24.3278 7.10308 25.8502 8.81595 26.8027 10.8384C27.7551 12.8609 28.0979 15.1085 27.7904 17.3159C27.554 19.0136 26.9406 20.6329 26.0009 22.0625C25.9076 22.2045 25.8111 22.3445 25.7114 22.4826C25.6807 22.5253 25.6531 22.5694 25.6289 22.6148C25.3887 23.0635 25.4664 23.6285 25.8677 23.9631C26.3095 24.3316 26.9757 24.2809 27.3171 23.821C27.3535 23.772 27.3895 23.7228 27.4252 23.6734C27.5499 23.5006 27.6704 23.3252 27.7866 23.1473C28.8803 21.4732 29.5947 19.5795 29.8711 17.5949C30.2328 14.9979 29.8296 12.3537 28.709 9.97428C27.5885 7.59489 25.7974 5.57974 23.5472 4.16675C21.2971 2.75375 18.6818 2.00192 16.0102 2C13.3386 1.99809 10.7222 2.74618 8.47 4.15595C6.21775 5.56572 4.42367 7.5783 3.29958 9.95608C2.17549 12.3339 1.76832 14.9775 2.12616 17.575C2.39962 19.5601 3.11115 21.4548 4.20237 23.1304C4.31832 23.3085 4.43855 23.484 4.56301 23.657C4.59861 23.7065 4.63456 23.7557 4.67086 23.8048C5.01155 24.2652 5.67768 24.3168 6.1201 23.949C6.52191 23.6149 6.60043 23.05 6.36091 22.601C6.33671 22.5556 6.30925 22.5114 6.27855 22.4687C6.17907 22.3305 6.08277 22.1903 5.98968 22.0482Z"
      fill="white"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M23.5604 25.0404C22.7368 25.7311 21.8262 26.3077 20.8513 26.7531C19.3231 27.4513 17.6708 27.8119 16 27.8119C14.3292 27.8119 12.6769 27.4513 11.1487 26.7531C10.1738 26.3077 9.26316 25.7311 8.4396 25.0404C8.24549 24.8776 8.05622 24.7085 7.87209 24.5332C7.83261 24.4957 7.79113 24.4612 7.74803 24.43C7.32161 24.1209 6.73575 24.1245 6.34301 24.4939C5.91057 24.9005 5.87639 25.5958 6.30118 26.011C6.34643 26.0552 6.39196 26.0991 6.43775 26.1427C6.65627 26.3507 6.88094 26.5514 7.11139 26.7444C8.07872 27.5548 9.14801 28.2314 10.2926 28.7544C12.0904 29.5757 14.0344 30 16 30C17.9656 30 19.9096 29.5757 21.7074 28.7544C22.852 28.2314 23.9213 27.5548 24.8886 26.7444C25.1191 26.5514 25.3437 26.3507 25.5622 26.1427C25.608 26.0992 25.6536 26.0552 25.6988 26.011C26.1236 25.5958 26.0894 24.9005 25.657 24.4939C25.2643 24.1246 24.6784 24.1209 24.252 24.43C24.2089 24.4612 24.1674 24.4957 24.1279 24.5332C23.9438 24.7085 23.7545 24.8776 23.5604 25.0404Z"
      fill="#ff3469"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M15.4369 22.3251C15.5887 22.4776 15.797 22.5573 16.0078 22.5529C16.215 22.5572 16.4237 22.4809 16.5788 22.3251L18.9939 19.8988C19.2881 19.6032 19.2784 19.1332 18.9722 18.8491C18.6661 18.5649 18.1793 18.5743 17.885 18.8699L16.7602 20L16.7602 10.845C16.7602 10.435 16.416 10.1026 15.9913 10.1026C15.5666 10.1026 15.2224 10.435 15.2224 10.845L15.2224 19.9667L14.1306 18.8699C13.8364 18.5743 13.3496 18.5649 13.0434 18.849C12.7372 19.1332 12.7276 19.6031 13.0218 19.8988L15.4369 22.3251Z"
      fill="#ff3469"
    />
  </SvgIcon>
);

export default TrendArrowDownThickIcon;
