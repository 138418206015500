import { useFollowPriorityMutation, useUnfollowPriorityMutation } from '@aily/graphql-sdk/core';
import { Alert, IconButton, styled, Tab, Tabs } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { usePrioritiesDimensions } from '../../hooks/usePrioritiesDimensions';
import { Star, StarOutlined } from '../../icons';
import { useModule } from '../../providers';
import { LoadingSkeleton } from '../LoadingSkeleton';
import NestedPrioritiesDimensions from './NestedPrioritiesDimensions';
import NestedSearchList from './NestedSearchList';
import { AddPrioritiesProps, DataPrioritiesResult, ListItemResult } from './types';

const StyledTab = styled(Tab)(({ theme }) => ({
  height: 32,
  ...theme.typography.small,
  borderRadius: 32,
  minHeight: 0,
  marginRight: theme.spacing(1.5),
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.text.secondary}`,
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.shortest,
  }),
  '&.Mui-selected, &:hover': {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
}));

export const AddPrioritiesDimensions: React.FC<AddPrioritiesProps> = ({ search, handleSearch }) => {
  const {
    priorities,
    dimensions,
    selectedPriority,
    selectedDimension,
    setSelectedDimension,
    setSelectedPriority,
    loading,
    error,
  } = usePrioritiesDimensions();

  const [parent, setParent] = useState<ListItemResult | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const moduleId = useModule()?.id ?? '';

  const prioritiesByCategory = useMemo(
    () => priorities[tabValue]?.priorities,
    [priorities, tabValue],
  );

  const combinedPriorities = useMemo(
    () => priorities.flatMap((category) => category.priorities),
    [priorities],
  );

  const filterList = useMemo(() => {
    if (!search) {
      return { filteredPriorities: [], filteredDimensions: [] };
    }

    const term = search.toLowerCase();

    if (!selectedPriority) {
      const filteredPriorities = combinedPriorities.filter((item) =>
        item.label?.toLowerCase().includes(term),
      );

      return { filteredPriorities, filteredDimensions: [] };
    } else {
      const deepFilterDimensions = (items: ListItemResult[]) =>
        items.filter((item) => item.label?.toLowerCase().includes(term));
      const filteredDimensions = deepFilterDimensions(dimensions);
      return { filteredPriorities: [], filteredDimensions };
    }
  }, [search, selectedPriority, combinedPriorities, dimensions]);

  const { filteredPriorities, filteredDimensions } = filterList;

  const currentList = useMemo(
    () => (priorities: ListItemResult[]) => {
      if (!selectedPriority) return search ? filteredPriorities : priorities;
      if (!selectedDimension)
        return search ? filteredDimensions : [selectedPriority, ...dimensions];
      return selectedDimension?.children ?? [];
    },
    [
      search,
      filteredPriorities,
      filteredDimensions,
      dimensions,
      selectedPriority,
      selectedDimension,
    ],
  );

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setSelectedPriority(null);
      setSelectedDimension(null);
      setParent(null);
      setTabValue(newValue);
    },
    [priorities],
  );

  const tabValueAfterSearch = useMemo(
    () => (category?: DataPrioritiesResult) => {
      if (category)
        return priorities?.findIndex((elem) => elem.categoryName === category.categoryName);
    },
    [priorities],
  );

  const drillDown = useCallback(
    (item: ListItemResult, category?: DataPrioritiesResult) => {
      handleSearch?.('');

      if (category) {
        const tabValue = tabValueAfterSearch(category);
        setTabValue(tabValue ?? 0);
      }

      if ('priorityId' in item) {
        setSelectedPriority(item);
        setSelectedDimension(null);
        setParent(item);
      } else if ('dimensionId' in item) {
        if (item.children && item.children.length > 0) return;
        setParent(item);
        setSelectedDimension(item);
      }
    },
    [tabValueAfterSearch, handleSearch],
  );

  const drillUp = useCallback(() => {
    if (selectedDimension) {
      const parent = findParentDimension(dimensions, selectedDimension);
      setSelectedDimension(parent);
    } else if (selectedPriority) {
      setParent(null);
      setSelectedPriority(null);
    }
  }, [selectedDimension, dimensions]);

  const findParentDimension = useCallback(
    (dimensionList: ListItemResult[], targetDimension: ListItemResult): ListItemResult | null => {
      for (const dimension of dimensionList) {
        if (dimension.children?.includes(targetDimension)) {
          return dimension;
        }
        const found = findParentDimension(dimension.children || [], targetDimension);
        if (found) return found;
      }
      return null;
    },
    [],
  );

  const [followPriority] = useFollowPriorityMutation();
  const [unfollowPriority] = useUnfollowPriorityMutation();

  const toggleFollow = useCallback(
    (item: ListItemResult) => {
      const { priorityId, sortOrder, isFollowed, dimensionCode } = item;
      const input = {
        moduleId,
        priorityId: (priorityId ? priorityId : selectedPriority?.priorityId) ?? 0,
        sortOrder: (sortOrder ? sortOrder : selectedPriority?.sortOrder) ?? 0,
        dimensionCode,
      };

      item.isFollowed = !item.isFollowed;

      if (isFollowed) {
        unfollowPriority({ variables: { input } });
      } else {
        followPriority({ variables: { input } });
      }
    },
    [unfollowPriority, followPriority, currentList, moduleId, selectedPriority],
  );

  const handleSecondaryAction = useCallback(
    (item: ListItemResult) => {
      return (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            toggleFollow(item);
          }}
          sx={{
            '&&': {
              borderLeft: 'none',
              color: (theme) =>
                item.isFollowed ? theme.palette.primary.main : theme.palette.text.secondary,
            },
          }}
        >
          {item.isFollowed ? <Star /> : <StarOutlined />}
        </IconButton>
      );
    },
    [toggleFollow],
  );

  const deepSearch = (data: ListItemResult[], term: string) => {
    return data.filter((item: ListItemResult) => item.label?.toLowerCase().includes(term));
  };

  const filteredResults = deepSearch(currentList(combinedPriorities), search ?? '');

  if (search) {
    if (!filteredResults.length)
      return (
        <Alert variant="outlined" severity="info">
          Nothing found
        </Alert>
      );

    return (
      <NestedSearchList
        currentItems={
          search
            ? selectedPriority
              ? filteredDimensions
              : filteredPriorities
            : currentList(combinedPriorities)
        }
        drillDown={drillDown}
        drillUp={drillUp}
        loading={loading}
        parent={parent?.label}
        secondaryAction={handleSecondaryAction}
        search={search}
        prioritiesCategory={priorities}
      />
    );
  }

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );
  }

  if (!prioritiesByCategory?.length) {
    return (
      <Alert variant="outlined" severity="info">
        No data
      </Alert>
    );
  }

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        sx={{ boxShadow: 'none', minHeight: 32, '& .MuiTabs-indicator': { display: 'none' } }}
      >
        {priorities.map((priorityCategory, index) => (
          <StyledTab
            key={index}
            value={index}
            label={priorityCategory.categoryName}
            data-testid="TabButton"
          />
        ))}
      </Tabs>
      <NestedPrioritiesDimensions
        currentItems={currentList(prioritiesByCategory)}
        drillDown={drillDown}
        drillUp={drillUp}
        loading={loading}
        parent={parent?.label}
        secondaryAction={handleSecondaryAction}
      />
    </>
  );
};

export default AddPrioritiesDimensions;
