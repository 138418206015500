import { Box } from '@mui/material';
import React from 'react';

import { Slideshow, SlideshowProps } from '../../../../components/Slideshow';
import { SlideShowProfileType } from '../../../../components/Slideshow/slideshowState';

export const AgentSlideshow: React.FC<SlideshowProps> = ({ children, ...rest }) => {
  return (
    <Box position="relative">
      <Slideshow profile={SlideShowProfileType.AGENT} sx={{ paddingTop: 0 }} {...rest}>
        {children}
      </Slideshow>
    </Box>
  );
};
