import React, { createContext, ProviderProps, useContext } from 'react';

import { RecommenderCardSize } from './RecommenderCard';

export interface RecommenderCardContextValue {
  size: RecommenderCardSize;
}

const RecommenderCardContext = createContext<RecommenderCardContextValue | undefined>(undefined);

export const RecommenderCardProvider: React.FC<ProviderProps<RecommenderCardContextValue>> = ({
  children,
  value,
}) => <RecommenderCardContext.Provider value={value}>{children}</RecommenderCardContext.Provider>;

export const useRecommenderCardContext = () => {
  return useContext(RecommenderCardContext);
};
