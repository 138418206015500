import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import { ReactNode, useCallback, useMemo } from 'react';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { lineClamp } from '../../theme/utils';
import { DataPrioritiesResult, ListItemResult } from './types';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  color: theme.palette.text.secondary,
  '&:last-child': {
    borderBottom: 'none',
  },
  '&.MuiListItem-root': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '&:hover, &.Mui-selected': {},
  '&.Mui-selected': {
    color: theme.palette.text.primary,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'initial',
    '& .MuiSvgIcon-root': {
      color: 'white',
      width: '20px',
      height: '20px',
    },
  },
  '& .MuiListItemSecondaryAction-root': {
    position: 'relative',
    top: 'auto',
    right: 'auto',
    transform: 'none',
  },
  '& .MuiListItemButton-root': {
    flexGrow: 0,
    '&:hover, &.Mui-selected, &.Mui-selected:hover': {},
    '&.Mui-selected': {
      color: theme.palette.text.primary,
    },
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
  '& .MuiIconButton-root': {
    width: 70,
    height: 33,
    marginRight: 0,
    borderRadius: 0,
    '&:hover': {},
  },
  '& mark': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: 0,
  },
  '& .MuiListItemText-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiTypography-root, & .MuiTypography-root + .MuiTypography-root': {
      ...lineClamp(1),
    },
    '& .MuiTypography-root:only-child': {
      ...lineClamp(2),
      lineHeight: 16 / 15,
    },
  },
}));

interface NestedSearchListProps {
  currentItems: ListItemResult[];
  drillDown: (item: ListItemResult, category?: DataPrioritiesResult) => void;
  drillUp: () => void;
  loading: boolean;
  parent?: string;
  secondaryAction: (item: ListItemResult) => ReactNode;
  prioritiesCategory?: DataPrioritiesResult[];
  search?: string;
}

const NestedSearchList: React.FC<NestedSearchListProps> = ({
  currentItems,
  drillDown,
  drillUp,
  loading,
  parent,
  secondaryAction,
  prioritiesCategory,
  search,
}) => {
  const Row = ({ index, style }: ListChildComponentProps) => {
    const item = currentItems[index];
    const isFirstRow = parent === item.label;
    const isDrillable = item.isDrillable || !isEmpty(item.children);

    const category = useMemo(
      () =>
        prioritiesCategory?.find((category) =>
          category.priorities.some(
            (priority) => priority.priorityId && priority.priorityId === item.priorityId,
          ),
        ),
      [prioritiesCategory, item],
    );

    const handleOnClick = useCallback(() => {
      if (!isDrillable) return;

      if (isFirstRow) drillUp();
      else drillDown(item, category);
    }, [isFirstRow, item, category]);

    if (loading) return <Skeleton />;

    return (
      <StyledListItem
        style={style}
        disablePadding
        key={index}
        secondaryAction={!isFirstRow && secondaryAction(item)}
      >
        <ListItemButton
          onClick={handleOnClick}
          sx={{ cursor: !isFirstRow && !isDrillable ? 'default' : 'pointer' }}
        >
          {isFirstRow ? (
            <>
              <ListItemIcon>
                <ChevronLeft />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body" color="white">
                    {parent}
                  </Typography>
                }
              />
            </>
          ) : (
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body" color="white">
                  <Highlighter
                    searchWords={[search as string]}
                    textToHighlight={item.label}
                    autoEscape
                  />
                </Typography>
              }
              secondary={
                <Typography variant="small" color="text.secondary" component="div">
                  {category?.categoryName}
                </Typography>
              }
            />
          )}
          {!isFirstRow && isDrillable && (
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
          )}
        </ListItemButton>
      </StyledListItem>
    );
  };

  if (!currentItems) return null;

  return (
    <FixedSizeList height={12 * 46} width="100%" itemSize={46} itemCount={currentItems.length}>
      {Row}
    </FixedSizeList>
  );
};

export default NestedSearchList;
