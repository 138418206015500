import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { useId } from 'react';

export const StarActive: React.FC<SvgIconProps> = (props) => {
  const id = useId();
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
      <defs>
        <linearGradient id={`${id}-gradient`} x2="100%">
          <stop stop-color="#31d4b6" />
          <stop offset="1" stop-color="#48ff9b" />
        </linearGradient>
      </defs>
      <path
        d="M13.7615 4.27739C14.6772 2.3443 17.3229 2.34431 18.2385 4.2774L21.0955 10.3088C21.1683 10.4623 21.3088 10.5688 21.4715 10.5934L27.8599 11.5606C29.9074 11.8705 30.725 14.4922 29.2434 15.9969L24.6207 20.6917C24.503 20.8112 24.4493 20.9833 24.4771 21.1521L25.5684 27.7813C25.9181 29.9059 23.7777 31.5262 21.9463 30.523L16.2323 27.3932C16.0869 27.3135 15.9131 27.3135 15.7677 27.3932L10.0537 30.523C8.22229 31.5262 6.08187 29.9059 6.43163 27.7813L7.52291 21.1521C7.55069 20.9833 7.49699 20.8112 7.37932 20.6917L2.75659 15.9969C1.27498 14.4922 2.09255 11.8705 4.14008 11.5606L10.5285 10.5934C10.6912 10.5688 10.8317 10.4623 10.9045 10.3088L13.7615 4.27739ZM16.4477 5.19825C16.2646 4.81162 15.7354 4.81163 15.5523 5.19824L12.6953 11.2297C12.3317 11.9973 11.6288 12.5294 10.8157 12.6524L4.42725 13.6196C4.01774 13.6816 3.85423 14.206 4.15055 14.5069L8.77329 19.2017C9.36164 19.7992 9.63011 20.6601 9.49122 21.5038L8.39994 28.133C8.32999 28.5579 8.75807 28.882 9.12434 28.6813L14.8384 25.5515C15.5656 25.1531 16.4344 25.1531 17.1616 25.5515L22.8757 28.6813C23.2419 28.882 23.67 28.5579 23.6001 28.133L22.5088 21.5038C22.3699 20.6601 22.6384 19.7992 23.2267 19.2017L27.8494 14.5069C28.1458 14.206 27.9823 13.6816 27.5727 13.6196L21.1843 12.6525C20.3712 12.5294 19.6683 11.9973 19.3047 11.2297L16.4477 5.19825Z"
        fill={`url(#${id}-gradient)`}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
