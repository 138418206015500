import { ColorTokenKey, defaultTheme } from '@aily/ui-theme';
import { styled, useTheme } from '@mui/material';
import React from 'react';

import { MaybeButtonBase } from '../../buttons';
import { Stack } from '../../Stack';
import { Typography } from '../../Typography';
import { CircularProgressBar, CircularProgressBarProps } from '../CircularProgressBar';

export interface DoublePulseGaugeProps {
  value?: number;
  valueText?: string;
  sentiment?: DoublePulseGaugeSentiment;
  onValueClick?: () => void;
  label?: string;
  secondaryValue?: number;
  secondaryValueText?: string;
  secondaryLabel?: string;
}

export type DoublePulseGaugeSentiment = 'positive' | 'negative' | 'warning';

const sentimentColorMap: Record<DoublePulseGaugeSentiment, ColorTokenKey> = {
  positive: 'gradient.mint.vertical-inverse',
  negative: 'gradient.pink.vertical',
  warning: 'gradient.orange.vertical',
};

const sentimentTextColorMap: Record<DoublePulseGaugeSentiment, ColorTokenKey> = {
  ...sentimentColorMap,
  // Text color for the positive sentiment is different from the bar
  positive: 'gradient.mint.vertical',
};

const gaugeSize = 296;
const secondaryGaugeSize = 258;
const secondaryGaugeOffset = (gaugeSize - secondaryGaugeSize) / 2;

const circularProgressBarProps: Omit<CircularProgressBarProps, 'value' | 'progressColor'> = {
  size: gaugeSize,
  strokeWidth: 15,
  trackColor: defaultTheme.color['background.light'],
  glow: true,
  shadowTip: true,
};

const secondaryCircularProgressBarProps: Omit<CircularProgressBarProps, 'value' | 'progressColor'> =
  {
    size: secondaryGaugeSize,
    strokeWidth: 12,
    trackColor: defaultTheme.color['background.light'],
    glow: false,
    shadowTip: true,
    x: secondaryGaugeOffset,
    y: secondaryGaugeOffset,
  };

const DoublePulseGaugeContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.tokens.spacing['medium'],
  height: '100%',
  padding: theme.tokens.spacing['xxxlarge'],
  userSelect: 'none',

  '& *': {
    maxWidth: '100%',
  },
}));

const DoublePulseGauge: React.FC<DoublePulseGaugeProps> = ({
  value = 0,
  valueText,
  sentiment = 'positive',
  onValueClick,
  label,
  secondaryValue = 0,
  secondaryValueText,
  secondaryLabel,
}) => {
  const theme = useTheme();
  const progressColor = theme.tokens.color[sentimentColorMap[sentiment]];

  return (
    <CircularProgressBar
      value={value}
      progressColor={progressColor}
      data-testid="double-pulse-gauge"
      {...circularProgressBarProps}
    >
      <CircularProgressBar
        value={secondaryValue}
        progressColor={theme.tokens.color['gradient.blue.vertical']}
        {...secondaryCircularProgressBarProps}
      />

      {/* Gauge Content */}
      <foreignObject x="0" y="0" width="100%" height="100%">
        <DoublePulseGaugeContent>
          <Stack direction="column" alignItems="center">
            <MaybeButtonBase
              onClick={onValueClick}
              disableHoverStyle
              data-testid="double-pulse-gauge__value-button"
            >
              <Typography
                variant="heading.H2"
                color={sentimentTextColorMap[sentiment]}
                lineClamp={1}
                data-testid="double-pulse-gauge__value"
              >
                {valueText}
              </Typography>
            </MaybeButtonBase>
            <Typography
              variant="heading.H9"
              color="text.regular"
              lineClamp={1}
              data-testid="double-pulse-gauge__label"
            >
              {label}
            </Typography>
          </Stack>
          <Stack direction="column" alignItems="center">
            <Typography
              variant="heading.H6"
              color="brand.blue"
              lineClamp={1}
              data-testid="double-pulse-gauge__secondary-value"
            >
              {secondaryValueText}
            </Typography>
            <Typography
              variant="body.regular"
              color="text.subtle"
              lineClamp={1}
              data-testid="double-pulse-gauge__secondary-label"
            >
              {secondaryLabel}
            </Typography>
          </Stack>
        </DoublePulseGaugeContent>
      </foreignObject>
    </CircularProgressBar>
  );
};

export default DoublePulseGauge;
