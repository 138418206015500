import * as T from '@aily/graphql-sdk/schema';
import { isEqual } from 'lodash-es';
import React from 'react';

import {
  ClosableModal,
  ClosableModalProps,
  ResponsiveScaleContainer,
} from '../../../../components';
import { useAgentJsonData } from '../../hooks';
import { Screen } from '../../types';
import { AgentSlideshowBuilder } from './AgentSlideshowBuilder';
import HeaderComponent from './HeaderComponent';

export interface AgentSlideshowModalProps extends Omit<ClosableModalProps, 'children'> {
  story: T.Story;
  onAfterScreen?: (screen: Screen) => React.ReactNode;
  baseAudioURL: string;
  decision: T.StoryDecision | null;
  onBackdropClose?: (forceRefetch: boolean) => void;
  onDecide?: () => void;
}

const contentBackground = 'linear-gradient(185deg, #31D4B6 0%, #114F44 5%, #111521 10%, #000 15%)';

export const AgentSlideshowModal: React.FC<AgentSlideshowModalProps> = React.memo(
  ({
    story,
    onAfterScreen,
    baseAudioURL,
    decision,
    onClose,
    onBackdropClose,
    onDecide,
    ...rest
  }) => {
    const agentJsonData = useAgentJsonData(story);

    return (
      <ClosableModal
        ContentBackgroundProps={{ sx: { background: contentBackground } }}
        CloseButtonProps={{ sx: { top: 10, fontSize: '28px' } }}
        {...rest}
      >
        <>
          <HeaderComponent onClose={onClose} />
          <ResponsiveScaleContainer containerWidth={1250} containerHeight={834}>
            {agentJsonData && (
              <AgentSlideshowBuilder
                agentJsonData={agentJsonData}
                onAfterScreen={onAfterScreen}
                baseAudioURL={baseAudioURL}
                decision={decision}
                onAgentClose={onClose}
                onBackdropClose={onBackdropClose}
                onDecide={onDecide}
              />
            )}
          </ResponsiveScaleContainer>
        </>
      </ClosableModal>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
