import { ColorTokenKey } from '@aily/ui-theme';
import { styled } from '@mui/material/styles';
import React from 'react';

import { Stack } from '../../Stack';
import { Typography } from '../../Typography';
import { MaybeCardActionArea } from '../CardActionArea';

export interface PriorityCardProps {
  title?: string;
  subtitle?: string;
  value?: string;
  sentiment?: PriorityCardSentiment;
  onClick?: () => void;
}

export type PriorityCardSentiment = 'positive' | 'negative';

const cardBackgroundMap: Record<PriorityCardSentiment, string> = {
  positive: 'linear-gradient(90deg, #2b995d, #195c38)', // Missing token
  negative: 'linear-gradient(90deg, #a2144d, #3c071c)', // Missing token
};

const valueColorMap: Record<PriorityCardSentiment, ColorTokenKey> = {
  positive: 'sentiment.positive',
  negative: 'sentiment.negative',
};

const PriorityCardRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'sentiment',
})<{ sentiment: PriorityCardSentiment }>(({ theme, sentiment }) => ({
  borderRadius: theme.tokens.borderRadius['medium'],
  width: 151,
  height: 64,
  background: cardBackgroundMap[sentiment],
}));

const PriorityCardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 2, // Missing token
  padding: theme.spacing(theme.tokens.spacing['small'], theme.tokens.spacing['medium']),
}));

const PriorityCard: React.FC<PriorityCardProps> = ({
  title,
  subtitle,
  value,
  sentiment = 'positive',
  onClick,
}) => (
  <PriorityCardRoot sentiment={sentiment} data-testid="priority-card">
    <MaybeCardActionArea onClick={onClick}>
      <PriorityCardContent>
        <Typography variant="body.bold-condensed" lineClamp={1} data-testid="priority-card__title">
          {title}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing="xxsmall">
          <Typography
            variant="body.xsmall-condensed"
            lineClamp={1}
            data-testid="priority-card__subtitle"
          >
            {subtitle}
          </Typography>
          <Typography
            variant="body.bold-condensed"
            color={valueColorMap[sentiment]}
            lineClamp={1}
            // Make sure the value is not pushed away by an overly long subtitle
            sx={{ flexShrink: 0, maxWidth: '50%' }}
            data-testid="priority-card__value"
          >
            {value}
          </Typography>
        </Stack>
      </PriorityCardContent>
    </MaybeCardActionArea>
  </PriorityCardRoot>
);

export default PriorityCard;
