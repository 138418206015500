import './services/authService';
import './services/monitoringService';

import { useAnalyticsIdentifyUser, useAnalyticsTrackPage } from '@aily/analytics-service';
import { useAuth, useAutoSignIn } from '@aily/auth-service';
import { useFeatureFlags } from '@aily/feature-flags-service';
import { useSentryRouteTags } from '@aily/monitoring-service';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AppRouter from '@/components/AppRouter';
import LaunchScreen from '@/components/LaunchScreen';
import ModalRoot from '@/components/ModalRoot';
import PageLayout from '@/components/PageLayout';
import { useAppStartedDispatcher } from '@/hooks/useAppStartedDispatcher';
import { useCaptureAuthError } from '@/hooks/useCaptureAuthError';
import { useModuleChangedDispatcher } from '@/hooks/useModuleChangedDispatcher';
import { useWatchForUpdates } from '@/hooks/useWatchForUpdates';
import { useInterceptions } from '@/providers/InterceptionsProvider';
import { getAppIsInitialized } from '@/store/app/slices';

function App() {
  const { error } = useAuth();
  // Waiting for feature flags to be loaded before rendering the router in case there are any routes based on feature flags
  const { loading: featureFlagsLoading } = useFeatureFlags();
  const { wasSubscriptionCalled } = useInterceptions();
  const appIsInitialized =
    useSelector(getAppIsInitialized) && !featureFlagsLoading && wasSubscriptionCalled;
  const { pathname } = useLocation();

  useAutoSignIn();
  useAnalyticsIdentifyUser();
  useAnalyticsTrackPage();
  useCaptureAuthError();
  useAppStartedDispatcher();
  useModuleChangedDispatcher();
  useWatchForUpdates();
  useSentryRouteTags(pathname);

  return (
    <>
      <ModalRoot />
      <AnimatePresence>
        {!appIsInitialized && (
          <LaunchScreen
            error={error}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
      {appIsInitialized && (
        <PageLayout>
          <AppRouter />
        </PageLayout>
      )}
    </>
  );
}

export default App;
