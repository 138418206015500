import { ColorTokenKey, IconSizingTokenKey } from '@aily/ui-theme';
import { styled, useTheme } from '@mui/material';
import React, { useId, useMemo } from 'react';

import { isLinearGradient, parseLinearGradient } from '../../utils';

export interface SvgIconProps extends React.ComponentProps<'svg'> {
  /**
   * The size of the icon.
   */
  size?: IconSizingTokenKey;
  /**
   * The color of the icon. If undefined, the icon retains its natural fill color or inherits the current color from the parent.
   */
  color?: ColorTokenKey;
  /**
   * An auxiliary prop for defining the color of the text in the icon.
   * Some colored icons have a text part that should be different based on the background color, for example `AiIcon`.
   */
  textColor?: ColorTokenKey;
}

const SvgIconRoot = styled('svg', {
  shouldForwardProp: (prop) => prop !== 'size',
})<SvgIconProps>(({ theme, size = 'icon.small' }) => ({
  display: 'inline-block',
  width: '1em',
  height: '1em',
  flexShrink: 0,
  fontSize: theme.tokens.sizing[size],
  fill: 'currentColor',
  userSelect: 'none',
}));

const SvgIcon: React.FC<SvgIconProps> = ({ size = 'icon.small', color, children, ...rest }) => {
  const theme = useTheme();
  const colorValue = color ? theme.tokens.color[color] : undefined;
  const id = useId();

  // Get gradient definition if applicable
  const gradient = useMemo(
    () =>
      colorValue && isLinearGradient(colorValue) ? parseLinearGradient(colorValue) : undefined,
    [colorValue],
  );

  // Process children to apply fills dynamically if color is defined
  const processedChildren = useMemo(() => {
    if (!colorValue) {
      // If no color is defined, return children as is
      return children;
    }

    // Apply gradient fill if available; otherwise use colorValue
    return React.Children.map(children, (child) =>
      React.isValidElement<React.ComponentProps<'path'>>(child) && child.type === 'path'
        ? React.cloneElement(child, { fill: gradient ? `url(#${id}-gradient)` : colorValue })
        : child,
    );
  }, [children, colorValue, gradient, id]);

  return (
    <SvgIconRoot size={size} {...rest}>
      {gradient && (
        <defs>
          <linearGradient id={`${id}-gradient`} gradientTransform={gradient.gradientTransform}>
            {gradient.stops.map(({ offset, color }, index) => (
              <stop key={index} offset={offset} stopColor={color} />
            ))}
          </linearGradient>
        </defs>
      )}
      {processedChildren}
    </SvgIconRoot>
  );
};

export default React.memo(SvgIcon);
